import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { 
  Box, Container, FormControl, InputLabel, MenuItem, Pagination, 
  PaginationItem, Select, TextField, Typography, LinearProgress,
  SelectChangeEvent, ThemeProvider, createTheme, CssBaseline,
  Dialog, DialogTitle, DialogContent, useMediaQuery, styled
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MainCard from "../components/cards/index";
import { ethers } from 'ethers';
import { getContractAddress, getContractABI } from "../utils/contractUtils";
import windows95Theme from '../windows95Theme';
import BetaDisclaimerDialog from './BetaDisclaimerDialog';

// Styled components with Windows 95 theme fonts
const Win95MenuItem = styled(MenuItem)({
  fontFamily: '"PT Sans", Arial, sans-serif',
  fontSize: '0.875rem',
});

const Win95InputLabel = styled(InputLabel)({
  fontFamily: '"VT323", monospace',
  fontSize: '1rem',
});

const Win95DialogTitle = styled(DialogTitle)({
  fontFamily: '"VT323", monospace',
  fontSize: '1.2rem',
  fontWeight: 'bold',
});

const Win95DialogContent = styled(DialogContent)({
  fontFamily: '"PT Sans", Arial, sans-serif',
  fontSize: '0.875rem',
});

const Win95Select = styled(Select)({
  '& .MuiSelect-select': {
    fontFamily: '"PT Sans", Arial, sans-serif',
    fontSize: '0.875rem',
  }
});

const Win95TextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontFamily: '"PT Sans", Arial, sans-serif',
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    fontFamily: '"VT323", monospace',
    fontSize: '1rem',
  }
});

interface TokenInfo {
  id: number;
  network: string;
  tokenAddress: string;
  tokenImage: string;
  tokenName: string;
  tokenSymbol: string;
  marketcap: string;
  replies: number;
  creatorAddress: string;
  webLink: string;
  telegramLink: string;
  twitterLink: string;
  price: string;
  virtualLP: string;
  createdAt: string;
  contracttype: number;
  updatedAt: string;
  description: string;
}

interface TokenData {
  marketcap: number;
  virtualLP: number;
  price: number;
  percentageSold: number;
  trades: number;
  recentTransactions?: number;
}

const theme = windows95Theme;
const TOKENS_PER_PAGE = 4;
const TOTAL_SUPPLY = ethers.parseUnits("1000000000", 18);

function DashboardPage() {
  const [tokenList, setTokenList] = useState<(TokenInfo & { parsedData: TokenData })[]>([]);
  const [orderType, setOrderType] = useState<string>('creationTime');
  const [orderFlag, setOrderFlag] = useState<string>('DESC');
  const [searchWord, setSearchWord] = useState('');
  const [network, setNetwork] = useState('Polygon');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedToken, setSelectedToken] = useState<TokenInfo | null>(null);

  const initializeProvider = useCallback(() => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    if (!infuraProjectId) {
      console.error("Infura Project ID is not set");
      return null;
    }
    const rpcUrl = `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`;
    return new ethers.JsonRpcProvider(rpcUrl);
  }, []);

  const getPricingMethodString = (contractType: number): string => {
    switch (contractType) {
        case 0:
            return ''; // Reserve Ratio (default)
        case 1:
            return 'linear';
        case 2:
            return 'logarithmic';
        case 3:
            return 'exponential';
        default:
            console.warn(`Unknown contract type: ${contractType}, defaulting to Reserve Ratio`);
            return '';
    }
};

const fetchTokenData = useCallback(async (token: TokenInfo, provider: ethers.Provider): Promise<TokenData> => {
  const pricingMethod = getPricingMethodString(token.contracttype);
  const contractABI = await getContractABI();
  const contractAddress = getContractAddress();
  const contract = new ethers.Contract(contractAddress, contractABI, provider);

  console.log(`Fetching data for token: ${token.tokenAddress}, Network: ${token.network}, Pricing Method: ${pricingMethod}, Contract Type: ${token.contracttype}`);

  try {
    const totalSupplyWei = BigInt(TOTAL_SUPPLY.toString());
    const totalSupplyEther = Number(ethers.formatUnits(totalSupplyWei, 18));

    let tokensSold: number;
    let price: number;
    let marketcap: number;
    let tokenReserve: number;
    let trades: number;

    if (token.contracttype === 0) { // Reserve Ratio
      try {
        const tokenReserveWei = await contract.getTokenReserve(token.tokenAddress);
        console.log(`Reserve Ratio - tokenReserveWei: ${tokenReserveWei.toString()}`);
        
        const adjustedTokenReserveWei = tokenReserveWei > totalSupplyWei ? totalSupplyWei : tokenReserveWei;
        const tokensSoldWei = totalSupplyWei - BigInt(adjustedTokenReserveWei.toString());
        
        tokenReserve = Number(ethers.formatUnits(adjustedTokenReserveWei, 18));
        tokensSold = Number(ethers.formatUnits(tokensSoldWei, 18));
        
        price = Number(ethers.formatUnits(await contract.getPrice(token.tokenAddress), 18));
        console.log(`Reserve Ratio - price: ${price}`);
        
        marketcap = price * totalSupplyEther;
        trades = Number(await contract.getTokenTrades(token.tokenAddress));
        console.log(`Reserve Ratio - trades: ${trades}`);
      } catch (error) {
        console.error("Error fetching Reserve Ratio token data:", error);
        throw error;
      }
    } else {
      // For Linear, Logarithmic, and Exponential
      try {
        console.log("Contract address:", contract.address);
        console.log("ABI for getTokensSold:", JSON.stringify(contract.interface.getFunction("getTokensSold"), null, 2));
        console.log("Calling getTokensSold for token:", token.tokenAddress);
        const [soldTokens, initialTokenReserve, currentTokenReserve, calculatedTokensSold] = await contract.getTokensSold(token.tokenAddress);
        console.log("Raw results from getTokensSold:", {
          soldTokens: soldTokens.toString(),
          initialTokenReserve: initialTokenReserve.toString(),
          currentTokenReserve: currentTokenReserve.toString(),
          calculatedTokensSold: calculatedTokensSold.toString()
        });
        tokensSold = Number(ethers.formatUnits(soldTokens, 18));
        console.log(`${pricingMethod} - tokensSold (formatted): ${tokensSold}`);
      } catch (error) {
        console.error("Error calling getTokensSold:", error);
        console.error("Error details:", JSON.stringify(error, null, 2));
        tokensSold = 0;
      }

      try {
        const tokenReserveWei = await contract.getTokenReserve(token.tokenAddress);
        console.log(`${pricingMethod} - tokenReserveWei: ${tokenReserveWei.toString()}`);
        const adjustedTokenReserveWei = tokenReserveWei > totalSupplyWei ? totalSupplyWei : tokenReserveWei;
        tokenReserve = Number(ethers.formatUnits(adjustedTokenReserveWei, 18));
        console.log(`${pricingMethod} - tokenReserve: ${tokenReserve}`);
      } catch (error) {
        console.error("Error calling getTokenReserve:", error);
        tokenReserve = 0;
      }

      try {
        trades = Number(await contract.getTokenTrades(token.tokenAddress));
        console.log(`${pricingMethod} - trades: ${trades}`);
      } catch (error) {
        console.error("Error calling getTokenTrades:", error);
        trades = 0;
      }

      try {
        price = Number(ethers.formatUnits(await contract.getPrice(token.tokenAddress), 18));
        console.log(`${pricingMethod} - price: ${price}`);
      } catch (error) {
        console.error("Error calling getPrice:", error);
        price = 0;
      }

      try {
        price = Number(ethers.formatUnits(await contract.getPrice(token.tokenAddress), 18));            
        marketcap = price * totalSupplyEther;
        console.log(`${pricingMethod} - marketcap: ${marketcap}`);
      } catch (error) {
        console.error("Error calculating marketcap:", error);
        marketcap = 0;
      }
    }

    const percentageSold = totalSupplyEther > 0 ? (tokensSold / (totalSupplyEther * 0.7)) * 100 : 0;

    console.log(`Token ${token.tokenAddress} - tokensSold: ${tokensSold}, totalSupply: ${totalSupplyEther}, percentageSold: ${percentageSold}`);

    const result: TokenData = {
      marketcap: marketcap,
      virtualLP: tokenReserve,
      price: price,
      percentageSold: Math.round(percentageSold * 100) / 100,
      trades: trades,
    };

    console.log(`Token ${token.tokenAddress} (Type ${token.contracttype}) - Final result:`, result);

    return result;
  } catch (error) {
    console.error("Error fetching token data:", error, "for token:", token.tokenAddress);
    return {
      marketcap: 0,
      virtualLP: 0,
      price: 0,
      percentageSold: 0,
      trades: 0,
    };
  }
}, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tokens`, {
        orderType,
        orderFlag,
        searchWord,
        network,
        pageNumber,
        pageSize: TOKENS_PER_PAGE
      });

      const provider = initializeProvider();
      if (!provider) {
        throw new Error("Failed to initialize provider");
      }

      const tokenListWithData = await Promise.all(response.data.tokenList.map(async (token: TokenInfo) => {
        const contractData = await fetchTokenData(token, provider);
        return { 
          ...token, 
          parsedData: contractData, 
          createdAt: new Date(token.createdAt),
          updatedAt: new Date(token.updatedAt)
        };
      }));

      // Sort the tokenListWithData based on orderType and orderFlag
      const sortedTokenList = tokenListWithData.sort((a, b) => {
        if (orderType === 'marketcap') {
          return orderFlag === 'ASC' ? a.parsedData.marketcap - b.parsedData.marketcap : b.parsedData.marketcap - a.parsedData.marketcap;
        } else if (orderType === 'updateTime') {
          return orderFlag === 'ASC' ? a.updatedAt.getTime() - b.updatedAt.getTime() : b.updatedAt.getTime() - a.updatedAt.getTime();
        } else { // creationTime
          return orderFlag === 'ASC' ? a.createdAt.getTime() - b.createdAt.getTime() : b.createdAt.getTime() - a.createdAt.getTime();
        }
      });

      setTokenList(sortedTokenList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching tokens:", error);
      setError("Failed to fetch tokens. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [orderType, orderFlag, searchWord, network, pageNumber, fetchTokenData, initializeProvider]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
    setPageNumber(1);
  };

  const handleOrderTypeChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    setOrderType(event.target.value as string);
    setPageNumber(1);
  };

  const handleOrderFlagChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    setOrderFlag(event.target.value as string);
    setPageNumber(1);
  };

  const handleHomeClick = () => {
    setPageNumber(1);
    setSearchWord('');
    setOrderType('creationTime');
    setOrderFlag('DESC');
  };

  const handleDescriptionClick = (token: TokenInfo) => (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Prevent the click from propagating to the card
    setSelectedToken(token);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedToken(null);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={windows95Theme}>
      <BetaDisclaimerDialog />
      <Container maxWidth="lg" sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 2 }}>
        <Grid2 container spacing={2} sx={{ mb: 2 }}>
          <Grid2 xs={12} sm={4}>
            <FormControl fullWidth size="small">
              <Win95InputLabel id="order-type-label">Sort By</Win95InputLabel>
              <Win95Select
                labelId="order-type-label"
                value={orderType}
                label="Sort By"
                onChange={handleOrderTypeChange}
              >
                <Win95MenuItem value="marketcap">Market Cap</Win95MenuItem>
                <Win95MenuItem value="creationTime">Creation Time</Win95MenuItem>
                <Win95MenuItem value="updateTime">Update Time</Win95MenuItem>
              </Win95Select>
            </FormControl>
          </Grid2>
          <Grid2 xs={12} sm={4}>
            <Win95TextField 
              fullWidth
              label="Search Keyword" 
              value={searchWord} 
              onChange={handleSearchChange}
              size="small"
            />
          </Grid2>
          <Grid2 xs={12} sm={4}>
            <FormControl fullWidth size="small">
              <Win95InputLabel id="order-flag-label">Order</Win95InputLabel>
              <Win95Select
                labelId="order-flag-label"
                value={orderFlag}
                label="Order"
                onChange={handleOrderFlagChange}
              >
                <Win95MenuItem value='ASC'>Ascending</Win95MenuItem>
                <Win95MenuItem value='DESC'>Descending</Win95MenuItem>
              </Win95Select>
            </FormControl>
          </Grid2>
        </Grid2>
      
        {isLoading ? (
          <Box sx={{ width: '100%', mb: 4 }}>
            <LinearProgress className="cyber-progress" />
          </Box>
        ) : error ? (
          <Typography 
            color="error"
            sx={{ 
              fontFamily: '"VT323", monospace',
              fontSize: '1.1rem'
            }}
          >
            {error}
          </Typography>
        ) : tokenList.length > 0 ? (
          <Grid2 container spacing={2}>
            {tokenList.map((token, index) => (
              <Grid2 xs={12} sm={6} md={3} key={`${token.tokenAddress}-${index}`}>
                <Link to={`/${token.network}/${token.tokenAddress}`} style={{ textDecoration: 'none' }}>
                  <MainCard
                    network={token.network}
                    image={token.tokenImage}
                    title={token.tokenName}
                    ticker={token.tokenSymbol}
                    market_cap={token.parsedData.marketcap}
                    trades={token.parsedData.trades}
                    deployer={token.creatorAddress}
                    web={token.webLink}
                    telegram={token.telegramLink}
                    twitter={token.twitterLink}
                    percentageSold={token.parsedData.percentageSold}
                    tokenData={token.parsedData}
                    createdAt={new Date(token.createdAt)}
                    recentTransactions={token.parsedData.recentTransactions || 0}
                    contracttype={token.contracttype}
                    description={token.description}
                    onDescriptionClick={handleDescriptionClick(token)}
                    isMobile={isMobile}
                  />
                </Link>
              </Grid2>
            ))}
          </Grid2>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography 
              variant="h6" 
              className="neon-text"
              sx={{ 
                fontFamily: '"VT323", monospace',
                fontSize: '1.2rem',
                color: 'var(--secondary-color)'
              }}
            >
              No tokens found. Try adjusting your search or filters.
            </Typography>
          </Box>
        )}
      
        {tokenList.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? "small" : "medium"}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{ 
                    fontFamily: '"VT323", monospace',
                    color: 'var(--text-color)', 
                    '&.Mui-selected': { 
                      backgroundColor: 'var(--primary-color)',
                      color: 'var(--background-color)'
                    },
                    fontSize: isMobile ? '0.7rem' : '0.8rem',
                  }}
                />
              )}
            />
          </Box>
        )}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Win95DialogTitle>{selectedToken?.tokenName}</Win95DialogTitle>
          <Win95DialogContent>
            <Typography sx={{ 
              fontFamily: '"PT Sans", Arial, sans-serif',
              fontSize: '0.875rem'
            }}>
              {selectedToken?.description || "No description available"}
            </Typography>
          </Win95DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}

export default DashboardPage;