import React, { useState } from 'react';
import { 
  Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button,
  ThemeProvider, CssBaseline, Container, useMediaQuery, Tabs, Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import windows95Theme from '../windows95Theme';
import ReactMarkdown from 'react-markdown';

// TypeScript interfaces
interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  [key: string]: any;
}

// Styled components
const Win95Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  padding: theme.spacing(2),
}));

const Win95Tab = styled(Tab)(({ theme }) => ({
  fontFamily: '"VT323", monospace',
  fontSize: '1rem',
  borderRadius: 0,
  border: `1px solid ${theme.palette.primary.main}`,
  borderBottom: 'none',
  marginRight: '2px',
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'inset -1px -1px #fff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey',
  },
}));

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    fontFamily: '"VT323", monospace',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  '& h1': {
    fontSize: '1.5rem',
  },
  '& h2': {
    fontSize: '1.2rem',
  },
  '& h3': {
    fontSize: '1.1rem',
  },
  '& p, & li': {
    fontFamily: '"PT Sans", Arial, sans-serif',
    fontSize: '0.875rem',
    lineHeight: 1.6,
  },
  '& ul, & ol': {
    paddingLeft: theme.spacing(3),
  },
  '& li': {
    marginBottom: theme.spacing(1),
  },
  '& p': {
    marginBottom: theme.spacing(2),
  },
  '& em': {
    fontStyle: 'italic',
  },
  '& strong': {
    fontWeight: 'bold',
  },
}));

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

// Tab content remains the same as before
const tab1Content = `# lowcap.fun: Where Every Token Created is a Surprise Adventure

Ever wanted to be part of a token launch that's actually fun? Welcome
to lowcap.fun, where each token is a mystery box of exciting mechanics!

## What Makes lowcap.fun Different?

### 🎲 Random Contract Features

Every token you create comes with a surprise twist! Will you get:

- A "QUANTUM FLIP" where buying decreases price and selling increases
it?

- A "TIME BOMB" that self-destructs in 24 hours?

- An "EVIL TWIN" that splits into two separate tokens?

- Or one of dozens of other exciting possibilities?

You won't know until you launch - that's part of the fun!

### 🎢 The Classic Exponential Curve

Every token starts with our proven fair launch mechanism:

- Price increases gradually as more people buy in

- No more instant sellouts or bot takeovers

- Fair distribution for all participants

### 🎮 Pre-Launch Gaming Elements

Each token contract has unique features active until launch, such as:

- Burn mechanics that remove tokens from supply

- Velocity modifiers that affect price movement

- Special time-based bonuses

- Reflection rewards for holders

- And many more surprises!

### 🔒 Built-in Safety Features

- Liquidity locks automatically at launch

- Smart contract handles everything transparently

- All games and features end at launch for stable trading

## How It Works

1. Create a token (and discover its special features!)

2. Share your unique token with others

3. Watch as everyone figures out the token's mechanics

4. Hit 70% sold for automatic launch and liquidity lock

5. Start trading normally on Uniswap

Ready to discover what kind of token you'll create? Every launch is
different, every token is unique, and you never know what you're going
to get until you try!`;

const tab2Content = `# lowcap.fun: Where Token Launches Meet Gaming Innovation

Welcome to the future of token launches! We're not just creating a
platform - we're building an ecosystem where every token is a unique
game, and every participant is a player in an ever-expanding universe of
possibilities.

## The Revolutionary Token System

Our platform already features:

- Randomized token contracts with surprise mechanics

- Dozens of possible special features like:
  * "GRAVITY" tokens that slow down with activity
  * "DECAY" tokens that burn over time
  * "GM" tokens with morning-only bonuses
  * "UNPREDICTABLE" tokens with random price changes

- All features active until launch, then transition to stable trading

- Fair exponential pricing curve baseline for every token

## Coming Soon: Your Complete Gaming Experience 🎮

### 🎯 Quest System

Level up through various challenges:

- Create tokens to discover rare contract types

- Complete missions based on your tokens' special features

- Earn rewards for identifying token mechanics

- Special achievements for mastering different token types

### 👾 Custom Avatars

- Build a character that reflects your trading style

- Track which token types you've discovered

- Display badges for rare token discoveries

- Level up based on your interaction with different token mechanics

### 🏆 Community Leaderboard

- Compete for most successful token launches

- Earn points for correctly guessing token types

- Special rankings for mastering different token mechanics

- Weekly challenges with unique token types

### ⚔️ The Arena

- Put your token strategy skills to the test

- Battle using tokens with different mechanics

- Special tournaments for specific token types

- Master the art of trading various token features

### 🛠️ Community Tools

- Token mechanic detection tools

- Strategy guides for different token types

- Historical analysis of successful launches

- Community predictions and discussions

### 💎 Community Token

- Our own special token with rotating mechanics

- Governance rights for suggesting new token features

- Access to beta testing of new token types

- Special privileges in the gaming ecosystem

## Join the Revolution

lowcap.fun is transforming token launches from simple transactions into
an exciting game of discovery and strategy. Every token creation is a
mystery, every launch is an adventure, and every participant is part of
our growing gaming community.

Start your journey today and discover what unique token mechanics await
you! From "HODL BOMBS" to "QUANTUM FLIPS," you never know what you
might discover next.

Ready to play the most innovative token launch game in crypto? Your
adventure begins at lowcap.fun! 🎲✨

*Note: Features are under development and will be released
progressively. Token mechanics are randomly assigned and only active
until launch to ensure fair trading afterward.*`;

const InfoPage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = windows95Theme;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Win95Card>
          <Typography 
            variant={isSmallScreen ? "h5" : "h4"} 
            gutterBottom 
            sx={{ fontFamily: '"VT323", monospace' }}
          >
            Welcome to lowcap.fun
          </Typography>
          
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="info tabs">
              <Win95Tab label="Fun (now)" id="tab-0" aria-controls="tabpanel-0" />
              <Win95Tab label="Adventure (soon)" id="tab-1" aria-controls="tabpanel-1" />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <Box sx={{ maxHeight: '70vh', overflow: 'auto', pr: 2 }}>
              <StyledMarkdown>{tab1Content}</StyledMarkdown>
            </Box>
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            <Box sx={{ maxHeight: '70vh', overflow: 'auto', pr: 2 }}>
              <StyledMarkdown>{tab2Content}</StyledMarkdown>
            </Box>
          </TabPanel>
        </Win95Card>
      </Container>
    </ThemeProvider>
  );
};

export default InfoPage;