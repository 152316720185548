import { createTheme } from '@mui/material/styles';

// First, we'll define our font imports
const googleFonts = `
  @import url('https://fonts.googleapis.com/css2?family=VT323&family=PT+Sans:wght@400;700&display=swap');
`;

// Add the font imports to the document
const style = document.createElement('style');
style.textContent = googleFonts;
document.head.appendChild(style);

const windows95Theme = createTheme({
  palette: {
    primary: {
      main: '#000080', // Navy blue
    },
    secondary: {
      main: '#008080', // Teal
    },
    background: {
      default: '#00807F', // Teal
      paper: '#C0C0C0', // White
    },
    text: {
      primary: '#000000', // Black
      secondary: '#333333', // Dark gray
    },
  },
  typography: {
    // Updated font stack with Google Fonts
    fontFamily: '"PT Sans", Arial, sans-serif',
    fontSize: 12,
    h1: {
      fontFamily: '"VT323", monospace',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: '"VT323", monospace',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: '"VT323", monospace',
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: '"VT323", monospace',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: '"VT323", monospace',
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: '"VT323", monospace',
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
    button: {
      fontFamily: '"VT323", monospace',
      fontSize: '1rem',
      textTransform: 'none',
    },
    body1: {
      fontFamily: '"PT Sans", Arial, sans-serif',
      fontSize: '0.875rem',
    },
    body2: {
      fontFamily: '"PT Sans", Arial, sans-serif',
      fontSize: '0.875rem',
    },
    caption: {
      fontFamily: '"VT323", monospace',
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"VT323", monospace',
          fontSize: '1rem',
          borderRadius: 0,
          boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
          padding: '2px 6px',
          minWidth: 'auto',
          '&:active': {
            boxShadow: 'inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: '"PT Sans", Arial, sans-serif',
          borderRadius: 0,
          backgroundColor: '#ffffff',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000080',
            borderWidth: '2px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000080',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000080',
            borderWidth: '2px',
          },
        },
        input: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: '"PT Sans", Arial, sans-serif',
          borderRadius: 0,
          backgroundColor: '#ffffff',
          '&:focus': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"PT Sans", Arial, sans-serif',
          color: '#000000',
          '&.Mui-focused': {
            color: '#000080',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff',
            '& fieldset': {
              borderColor: '#000080',
              borderWidth: '2px',
            },
            '&:hover fieldset': {
              borderColor: '#000080',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000080',
              borderWidth: '2px',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff',
            '& fieldset': {
              borderColor: '#000080',
              borderWidth: '2px',
            },
            '&:hover fieldset': {
              borderColor: '#000080',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000080',
              borderWidth: '2px',
            },
          },
        },
      },
    },
  },
});

export default windows95Theme;