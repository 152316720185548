import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { MainRouter } from "../../routers";
import Logo from '../../assets/images/logo.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HelpIcon from '@mui/icons-material/Help';
import BetaDisclaimerDialog from './BetaDisclaimerDialog2';
import { 
  Box, Divider, Drawer, IconButton, List, ListItem, 
  ListItemButton, ListItemText, Toolbar, styled, Typography  
} from "@mui/material";

const drawerWidth = 240;

const Win95Drawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#c0c0c0',
    borderRight: '2px solid #808080',
    boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  },
}));

const Win95ListItem = styled(ListItem)({
  padding: 0,
});

const Win95ListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: '2px 6px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const Win95ListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontFamily: '"PT Sans", Arial, sans-serif',
    fontSize: '0.875rem',
  },
});

const Win95IconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  fontFamily: '"VT323", monospace',
}));

const LogoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: '"VT323", monospace',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginRight: 'auto',
}));

const Win95Logo = styled('img')({
  width: '64px',
  height: '64px',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '1rem',
  '@media (max-width: 600px)': {
    marginTop: '0.5rem',
  },
});

const BetaText = styled(Typography)({
  fontFamily: '"VT323", monospace',
  fontSize: '1rem',
});

interface SidebarProps {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function Sidebar({ window, mobileOpen, handleDrawerToggle }: SidebarProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const drawer = (
    <Box>
      <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', minHeight: '22px', padding: '2px 6px' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <LogoText>
            LOWCAP
          </LogoText>
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <BetaText>beta</BetaText>
          <IconButton
            size="small"
            onClick={handleOpenDialog}
            sx={{
              padding: '2px',
              color: '#000',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <HelpIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        </Box>  
        <Win95IconButton
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuOpenIcon />
        </Win95IconButton>
      </Toolbar>
      <Divider sx={{ backgroundColor: '#808080', width: '100%' }} />
      <List>
        {MainRouter.main.map((router, index) => (
          <Link key={index} to={router.path} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Win95ListItem disablePadding>
              <Win95ListItemButton>
                <Win95ListItemText primary={router.title} />
              </Win95ListItemButton>
            </Win95ListItem>
          </Link>
        ))}
      </List>
      <Divider sx={{ backgroundColor: '#808080', width: '100%' }} />
      <LogoContainer>
        <Win95Logo src={Logo} alt="logo" />
      </LogoContainer>
      <BetaDisclaimerDialog open={isDialogOpen} onClose={handleCloseDialog} />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Win95Drawer
        variant="temporary"
        container={container}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
            width: '80%', 
            maxWidth: '300px',
          },
        }}
      >
        {drawer}
      </Win95Drawer>
      <Win95Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
        open
      >
        {drawer}
      </Win95Drawer>
    </Box>
  );
}

export default Sidebar;