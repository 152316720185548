// contractUtils.ts
import { 
  POLYGON_RANDOM_CONTRACT_ADDRESSES
} from "../abis/FairLaunchFactoryAddress";
import fairLaunchFactoryABI from "../abis/FairLaunchFactory.json";

// Map contract addresses to their names
export const contractNameMap: { [key: string]: string } = {
  "0x3456789012345678901234567890123456789012": "unpredictable",
  "0x4567890123456789012345678901234567890123": "nosale",
  "0x5678901234567890123456789012345678901234": "hitit",
  "0x6789012345678901234567890123456789012345": "oneand",
  "0x7890123456789012345678901234567890123456": "uponly",
  "0x8901234567890123456789012345678901234567": "burnone",
  "0x9012345678901234567890123456789012345678": "burntwo",
  "0x0123456789012345678901234567890123456789": "burnfive",
  "0x1234567890123456789012345678901234567890": "burner",
  "0x2345678901234567890123456789012345678901": "eviltwin",
  "0x3456789012345678901234567890123456789512": "timebomb",
  "0x4567850123456789012345678901234567890123": "timebomb2",
  "0x5678901235567890123456789012345678901234": "velocity",
  "0x6789012355678901234567890123456789012345": "gravity",
  "0x7890123456789012345678951234567890123456": "decay",
  "0x8901234567890123456784012345678901234567": "flip",
  "0x9012345678901234567850123456789012345678": "feesus",
  "0x0123456789012345678901234567890153456789": "babyfeesus",
  "0x1234567890123456789012355678901234567890": "free",
  "0x2345678901234567890123556789012345678901": "irs",
  "0x2345998901234567890123456789012345678901": "gm",
  "0x2345678988234567890123456789012345678901": "early",
  "0x2345678891234567890123456789012345678901": "late",
  "0x074b5827c6b4021df7c0f6f6b6553fd2a1b16778": "exponential"
};

export const getContractAddress = (): string => {
  return getRandomContractAddress();
};

const getRandomContractAddress = (): string => {
  const randomIndex = Math.floor(Math.random() * POLYGON_RANDOM_CONTRACT_ADDRESSES.length);
  return POLYGON_RANDOM_CONTRACT_ADDRESSES[randomIndex];
};

export const getContractABI = async (): Promise<any> => {
  const randomAddress = getRandomContractAddress();
  return await getRandomContractABI(randomAddress);
};

const getRandomContractABI = async (address: string): Promise<any> => {
  const contractName = contractNameMap[address];
  
  if (!contractName) {
    console.log(`No contract name found for address: ${address}`);
    console.log(`Using default contract: FairLaunchFactory`);
    return fairLaunchFactoryABI; // Fallback to default ABI
  }

  try {
    const abiModule = await import(`../abis/${contractName}.json`);
    console.log(`Using contract: ${contractName}`);
    return abiModule.default;
  } catch (error) {
    console.log(`Failed to load ABI for contract: ${contractName}. Error: ${error}`);
    console.log(`Falling back to default contract: FairLaunchFactory`);
    return fairLaunchFactoryABI; // Fallback to default ABI
  }
};

export const getHref = (chainName: string | undefined, url: string, hash: string = ""): string => {
  if (!chainName) return "";
  let baseUrl = "";
  switch (chainName.toLowerCase()) {
    case "ethereum":
      baseUrl = "https://etherscan.io";
      break;
    case "sepolia":
      baseUrl = "https://sepolia.etherscan.io";
      break;
    case "base":
      baseUrl = "https://basescan.org";
      break;
    case "arbitrum":
      baseUrl = "https://arbiscan.io";
      break;
    case "avax":
      baseUrl = "https://snowtrace.io";
      break;
    case "polygon":
      baseUrl = "https://polygonscan.com";
      break;
    default:
      return "";
  }
  const type = hash === "hash" ? "tx" : "address";
  return `${baseUrl}/${type}/${url}`;
};