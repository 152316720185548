import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';

const Windows95Warning = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L2 20H22L12 2Z" stroke="black" stroke-width="1" fill="#000000"/>
    <path d="M12 4L3.5 19H20.5L12 4Z" fill="#ffdd00"/>
    <path d="M12 4L3.5 19H20.5L12 4Z" 
          fill="#fff5cc" 
          fill-opacity="0.5"
          transform="translate(-0.5, -0.5)"/>
    <rect x="11" y="8" width="2" height="6" fill="black"/>
    <rect x="11" y="15" width="2" height="2" fill="black"/>
  </svg>
);

const BetaDisclaimerDialog = () => {
  const [open, setOpen] = useState(true);
  const [acknowledged, setAcknowledged] = useState(false);

  const handleClose = () => {
    if (acknowledged) {
      localStorage.setItem('betaDisclaimerAcknowledged', 'true');
      setOpen(false);
    }
  };

  useEffect(() => {
    const hasAcknowledged = localStorage.getItem('betaDisclaimerAcknowledged');
    if (hasAcknowledged === 'true') {
      setOpen(false);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          backgroundColor: '#c0c0c0',
          border: '2px solid #000',
          borderRadius: '0',
          boxShadow: 'inset -1px -1px #0a0a0a,inset 1px 1px #fff,inset -2px -2px grey,inset 2px 2px #dfdfdf',
          padding: '2px'
        }
      }}
    >
      <DialogTitle 
        className="flex items-center gap-2"
        sx={{
          backgroundColor: '#000080',
          color: '#fff',
          padding: '3px 8px',
          fontSize: '1rem',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Windows95Warning />
        <Typography variant="h6" component="span" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Beta Version Notice
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, backgroundColor: '#c0c0c0' }}>
        <Box sx={{ '& ul': { margin: '1rem 0' } }}>
          <Typography variant="body1">
            Welcome to our Beta release! Please be aware that:
          </Typography>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>
              This is a beta version of the platform and may contain bugs or incomplete features
            </li>
            <li>
              The platform is not optimized for mobile
            </li>
            <li>
              Data displayed may not be fully accurate and should be verified independently
            </li>
            <li>
              Features and functionality may change without prior notice during the beta period
            </li>
            <li>
              Performance issues may occur as we optimize the platform
            </li>
          </ul>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Participation in beta counts toward platform rewards, progress, etc. and will include its own unique rewrds. We value your feedback and encourage you to report any issues or suggestions to help us improve the platform.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox 
                checked={acknowledged}
                onChange={(e) => setAcknowledged(e.target.checked)}
                sx={{
                  color: '#000',
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }}
              />
            }
            label="I understand that this is a beta release and accept its current limitations"
            sx={{ mt: 2, color: '#000' }}
          />
        </Box>
      </DialogContent>
      <DialogActions 
        sx={{ 
          padding: '8px',
          backgroundColor: '#c0c0c0',
          justifyContent: 'flex-end',
          gap: 1
        }}
      >
        <Button
          onClick={handleClose}
          disabled={!acknowledged}
          sx={{
            backgroundColor: '#c0c0c0',
            border: '2px solid #000',
            borderRadius: '0',
            color: '#000',
            textTransform: 'none',
            boxShadow: 'inset -1px -1px #0a0a0a,inset 1px 1px #fff,inset -2px -2px grey,inset 2px 2px #dfdfdf',
            '&:hover': {
              backgroundColor: '#c0c0c0',
            },
            '&:disabled': {
              backgroundColor: '#c0c0c0',
              color: '#808080',
            },
            padding: '4px 16px',
            minWidth: '70px'
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BetaDisclaimerDialog;