import React, { useEffect, useRef, useState, useMemo } from 'react';
import { createChart, ColorType, IChartApi, CandlestickData, HistogramData, Time, LineData, ISeriesApi, PriceFormat } from 'lightweight-charts';
import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';

interface ChartProps {
  data: {
    price: CandlestickData<Time>[];
    volume: HistogramData<Time>[];
  };
  latestPrice: string;
  contractType: number;
}

const Chart: React.FC<ChartProps> = ({ data, latestPrice, contractType }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candlestickSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const volumeSeriesRef = useRef<ISeriesApi<"Histogram"> | null>(null);
  const areaSeriesRef = useRef<ISeriesApi<"Area"> | null>(null);
  const isComponentMounted = useRef(true);
  const [error, setError] = useState<string | null>(null);
  const [processedData, setProcessedData] = useState<{
    price: CandlestickData<Time>[];
    volume: HistogramData<Time>[];
    area: LineData[];
  }>({ price: [], volume: [], area: [] });

  const formatPrice = (price: number): string => {
    if (isNaN(price) || price === 0) return '0';
    
    const priceString = (price / 1e18).toFixed(18);
    const [integerPart, decimalPart] = priceString.split('.');
    
    if (integerPart !== '0') {
      return (price / 1e18).toFixed(4);
    }

    const leadingZeros = decimalPart.match(/^0+/)?.[0].length || 0;
    const significantDigits = decimalPart.slice(leadingZeros, leadingZeros + 4);

    return `0.0${'0'.repeat(leadingZeros)}${significantDigits}`;
  };

  const scalePrice = (price: BigNumber): number => {
    if (price.isNaN() || !price.isFinite()) {
      console.warn(`Invalid price detected: ${price.toString()}`);
      return 0;
    }
    const scaleFactor = new BigNumber(10).pow(18);
    return price.times(scaleFactor).toNumber();
  };

  const validateAndProcessData = useMemo(() => (inputData: ChartProps['data'], latestPrice: string) => {
    try {
      const validPriceData = inputData.price.filter(item => 
        item.time != null &&
        !isNaN(new BigNumber(item.open).toNumber()) &&
        !isNaN(new BigNumber(item.high).toNumber()) &&
        !isNaN(new BigNumber(item.low).toNumber()) &&
        !isNaN(new BigNumber(item.close).toNumber())
      ).map(item => ({
        ...item,
        open: scalePrice(new BigNumber(item.open)),
        high: scalePrice(new BigNumber(item.high)),
        low: scalePrice(new BigNumber(item.low)),
        close: scalePrice(new BigNumber(item.close)),
      }));

      const validVolumeData = inputData.volume.filter(item => 
        item.time != null && !isNaN(item.value)
      );

      const latestPriceNumber = new BigNumber(latestPrice).isNaN() ? 0 : scalePrice(new BigNumber(latestPrice));
      const currentTime = Math.floor(Date.now() / 1000);

      // Update or add latest price data
      if (validPriceData.length > 0) {
        const lastCandle = validPriceData[validPriceData.length - 1];
        const candlePeriod = 300; // 5 minutes in seconds
        if (currentTime - (lastCandle.time as number) < candlePeriod) {
          lastCandle.close = latestPriceNumber;
          lastCandle.high = Math.max(lastCandle.high, latestPriceNumber);
          lastCandle.low = Math.min(lastCandle.low, latestPriceNumber);
        } else {
          validPriceData.push({
            time: currentTime as Time,
            open: latestPriceNumber,
            high: latestPriceNumber,
            low: latestPriceNumber,
            close: latestPriceNumber,
          });
        }
      } else {
        validPriceData.push({
          time: currentTime as Time,
          open: latestPriceNumber,
          high: latestPriceNumber,
          low: latestPriceNumber,
          close: latestPriceNumber,
        });
      }

      const areaData: LineData[] = validPriceData.map(item => ({
        time: item.time,
        value: (item.low + item.high) / 2,
      }));

      return {
        price: validPriceData,
        volume: validVolumeData,
        area: areaData,
      };
    } catch (error) {
      console.error('Error processing chart data:', error);
      setError('Error processing chart data. Please try again later.');
      return { price: [], volume: [], area: [] };
    }
  }, []);

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (chartContainerRef.current && !chartRef.current) {
      const handleResize = () => {
        if (chartRef.current && isComponentMounted.current) {
          chartRef.current.applyOptions({ width: chartContainerRef.current!.clientWidth });
        }
      };

      try {
        const chart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: 400,
          layout: {
            background: { type: ColorType.Solid, color: '#C0C0C0' },
            textColor: '#000000',
          },
          grid: {
            vertLines: { color: '#808080' },
            horzLines: { color: '#808080' },
          },
          rightPriceScale: {
            borderColor: '#000000',
            scaleMargins: {
              top: 0.1,
              bottom: 0.2,
            },
            visible: true,
          },
          timeScale: {
            borderColor: '#000000',
            timeVisible: true,
            secondsVisible: false,
          },
        });

        const candlestickSeries = chart.addCandlestickSeries({
          upColor: '#008000',
          downColor: '#FF0000',
          borderVisible: false,
          wickUpColor: '#008000',
          wickDownColor: '#FF0000',
          priceScaleId: 'right',
        });

        const areaSeries = chart.addAreaSeries({
          topColor: 'rgba(0, 0, 255, 0.5)',
          bottomColor: 'rgba(0, 0, 255, 0.0)',
          lineColor: 'rgba(0, 0, 255, 1)',
          lineWidth: 2,
          priceScaleId: 'right',
          lastValueVisible: false,
          priceLineVisible: false,
        });

        const volumeSeries = chart.addHistogramSeries({
          color: '#0000FF',
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: 'volume',
        });

        chartRef.current = chart;
        candlestickSeriesRef.current = candlestickSeries;
        areaSeriesRef.current = areaSeries;
        volumeSeriesRef.current = volumeSeries;

        window.addEventListener('resize', handleResize);
      } catch (error) {
        console.error('Error creating chart:', error);
        setError('Error creating chart. Please try again later.');
      }

      return () => {
        window.removeEventListener('resize', handleResize);
        if (chartRef.current && isComponentMounted.current) {
          chartRef.current.remove();
          chartRef.current = null;
          candlestickSeriesRef.current = null;
          areaSeriesRef.current = null;
          volumeSeriesRef.current = null;
        }
      };
    }
  }, []);

  useEffect(() => {
    console.log('Raw data:', JSON.stringify(data, (key, value) => 
      typeof value === 'number' && !isFinite(value) ? `Non-finite number: ${value}` : value
    ));
    console.log('Latest price:', latestPrice);
    console.log('Contract type:', contractType);
    const processedData = validateAndProcessData(data, latestPrice);
    console.log('Processed data:', JSON.stringify(processedData, (key, value) => 
      typeof value === 'number' && !isFinite(value) ? `Non-finite number: ${value}` : value
    ));
    setProcessedData(processedData);
  }, [data, latestPrice, contractType, validateAndProcessData]);

  useEffect(() => {
    if (isComponentMounted.current && chartRef.current && candlestickSeriesRef.current && volumeSeriesRef.current && areaSeriesRef.current) {
      try {
        candlestickSeriesRef.current.setData(processedData.price);
        volumeSeriesRef.current.setData(processedData.volume);
        areaSeriesRef.current.setData(processedData.area);

        chartRef.current.timeScale().fitContent();

        const prices = processedData.price.flatMap(item => [item.low, item.high, item.open, item.close]);
        const validPrices = prices.filter(price => isFinite(price));
        const minPrice = Math.min(...validPrices);
        const maxPrice = Math.max(...validPrices);

        const priceRange = maxPrice - minPrice;
        const precision = priceRange === 0 ? 2 : Math.max(0, Math.ceil(-Math.log10(priceRange / 2)));
        const minMove = Math.pow(10, -precision);

        const priceFormatter = (price: number) => {
          if (!isFinite(price)) {
            console.warn(`Infinite price detected: ${price}`);
            return '';  // Return empty string instead of 'N/A'
          }
          const formattedPrice = formatPrice(price);
          return formattedPrice === '0' ? '' : formattedPrice;  // Return empty string for zero
        };

        const customPriceFormat: PriceFormat = {
          type: 'custom',
          minMove: minMove,
          formatter: priceFormatter,
        };

        candlestickSeriesRef.current.applyOptions({
          priceFormat: customPriceFormat,
        });
  
        areaSeriesRef.current.applyOptions({
          priceFormat: customPriceFormat,
          lastValueVisible: false,
        });

        volumeSeriesRef.current.applyOptions({
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: 'volume',
        });

        chartRef.current.priceScale('right').applyOptions({
          autoScale: true,
          borderColor: '#000000',
          scaleMargins: {
            top: 0.1,
            bottom: 0.2,
          },
          ticksVisible: false,  // Hide ticks
          borderVisible: true,
          entireTextOnly: true,
        });

        chartRef.current.priceScale('volume').applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          },
          visible: false,
        });

        chartRef.current.applyOptions({
          localization: {
            priceFormatter: priceFormatter,
          },
        });

      } catch (error) {
        console.error('Error updating chart:', error);
        setError('Error updating chart. Please try again later.');
      }
    }
  }, [processedData, contractType]);

  if (error) {
    return (
      <Typography 
        color="error" 
        sx={{ 
          fontFamily: '"MS Sans Serif", Arial, sans-serif',
          fontSize: '12px',
          color: '#FF0000',
        }}
      >
        {error}
      </Typography>
    );
  }

  return (
    <Box 
      ref={chartContainerRef} 
      sx={{ 
        width: '100%', 
        height: '400px',
        border: '2px solid #808080',
        boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #dfdfdf',
      }} 
    />
  );
};

export default Chart;