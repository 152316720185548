import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, LinearProgress, Button, ButtonProps, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, Eip1193Provider, ethers } from 'ethers';
import axios from "axios";
import { socket } from "../utils/socket";
import { getContractAddress, getContractABI } from "../utils/contractUtils";
import { styled, Theme } from "@mui/material/styles";
import windows95Theme from '../windows95Theme';
import {
    POLYGON_RANDOM_CONTRACT_ADDRESSES
  } from "../abis/FairLaunchFactoryAddress";
import { contractNameMap } from "../utils/contractUtils"; // Add this import

const pinataApiKey = 'd94f8086b76748073f77';
const pinataSecretApiKey = '42117f4fb179600ed6cb9fa75ba0096682f52f976352aaadc810f23dee204d88';

const EXPECTED_INITIAL_AMOUNT = '25';

interface Win95ButtonProps extends ButtonProps {
    component?: React.ElementType;
  }

const Win95Box = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
    fontFamily: '"PT Sans", Arial, sans-serif',
  }));
  
  const Win95Typography = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.h1.fontFamily, // Using VT323 from theme
    color: theme.palette.text.primary,
  }));
  
  const Win95TextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      fontFamily: theme.typography.body1.fontFamily, // Using PT Sans from theme
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark,
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.text.primary,
    },
    '& .MuiInputBase-input': {
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.text.primary,
    },
  }));
  
  const Win95Button = styled(Button)<Win95ButtonProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.button.fontFamily, // Using VT323 from theme
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      boxShadow: 'inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey',
    },
  }));
  
  const Win95Card = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
    fontFamily: theme.typography.body1.fontFamily,
  }));
  
  const Win95CardContent = styled(CardContent)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body1.fontFamily,
  }));
  
  const Win95Select = styled(Select)(({ theme }) => ({
    fontFamily: theme.typography.body1.fontFamily,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.body1.fontFamily,
    },
  }));
  
  const Win95FormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputLabel-root': {
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.text.primary,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
  }));
  
  const Win95ProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 0,
    border: `2px solid ${theme.palette.grey[700]}`,
    backgroundColor: theme.palette.background.paper,
    '& .MuiLinearProgress-bar': {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
    },
  }));

// Add this new component for an animated progress bar
const AnimatedWin95ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Win95ProgressBar variant="determinate" value={progress} />;
};

export default function CreateTokenPage() {
    const navigate = useNavigate();
    const [deployModal, setDeployModal] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { open } = useWeb3Modal();

    const [coinName, setCoinName] = useState("");
    const [coinTicker, setCoinTicker] = useState("");
    const [description, setDescription] = useState("");
    const [imageLink, setImageLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [telegramLink, setTelegramLink] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const [webLink, setWebLink] = useState('');
    const [signer, setSigner] = useState<ethers.Signer | null>(null);
    const [fairLaunchContract, setFairLaunchContract] = useState<Contract | null>(null);
    const [nativeBalanceString, setNativeBalanceString] = useState("");
    const [initBuyAmount, setInitBuyAmount] = useState(EXPECTED_INITIAL_AMOUNT);
    const [minDeploymentAmount, setMinDeploymentAmount] = useState(EXPECTED_INITIAL_AMOUNT);
    const [pricingMethod, setPricingMethod] = useState<string>('random');
    const [contractAddress, setContractAddress] = useState(() => {
        const randomIndex = Math.floor(Math.random() * POLYGON_RANDOM_CONTRACT_ADDRESSES.length);
        return POLYGON_RANDOM_CONTRACT_ADDRESSES[randomIndex];
    });
    const [isDeploying, setIsDeploying] = useState(false);
    const [contractType, setContractType] = useState(() => {
      // Initialize with a random number between 0 and 24
      return Math.floor(Math.random() * 24);
  });

    const handleClickOpen = () => {
        if (!isConnected) {
            open();
            return;
        }
        setDeployModal(true);
    };

    const tokenDeploy = async () => {
      if (!isConnected || !fairLaunchContract) {
          alert("Please connect wallet and ensure contract is initialized");
          return;
      }

      try {
          setIsDeploying(true);
          console.log("Deploying token with parameters:", {
              name: coinName,
              symbol: coinTicker,
              value: ethers.parseEther(initBuyAmount).toString(),
              pricingMethod: pricingMethod,
              contractType: contractType
          });

           // Get contract name from the address mapping
           const contractName = contractNameMap[contractAddress] || 'FairLaunchFactory';
           const abiFileName = `${contractName}.json`;
           
           console.log("=== Contract Deployment Details ===");
           console.log("Contract Type Number:", contractType);
           console.log("Contract Address:", contractAddress);
           console.log("Using Contract:", contractName);
           console.log("Using ABI file:", abiFileName);

          const tx = await fairLaunchContract.createToken(
              coinName, 
              coinTicker,
              { value: ethers.parseEther(initBuyAmount) }
          );
          
          console.log("Transaction sent:", tx.hash);
          
          const receipt = await tx.wait();
          console.log("Transaction receipt:", receipt);

          await new Promise(resolve => setTimeout(resolve, 5000)); // 5 second delay
          
        let tokenAddress;
        const tokenCreatedEvent = receipt.logs.find((log: any) => {
          try {
            const parsedLog = fairLaunchContract.interface.parseLog(log);
            return parsedLog && parsedLog.name === "TokenCreated";
          } catch (e) {
            return false;
          }
        });
    
        if (!tokenCreatedEvent) {
          console.warn("TokenCreated event not found in logs. Falling back to contract call.");
          tokenAddress = await fairLaunchContract.getLastCreatedToken();
          if (!tokenAddress) {
            throw new Error("Failed to retrieve token address");
          }
          console.log("Retrieved token address:", tokenAddress);
        } else {
          const parsedEvent = fairLaunchContract.interface.parseLog(tokenCreatedEvent);
          tokenAddress = parsedEvent ? parsedEvent.args[0] : null;
          console.log("Token address from event:", tokenAddress);
        }
    
        console.log("Fetching token market cap...");
        const tokenMarketCap = await fairLaunchContract.getTokenMarketCap(tokenAddress);
        console.log("Raw token market cap:", tokenMarketCap.toString());
    
        console.log("Fetching token price...");
        const tokenPrice = await fairLaunchContract.getPrice(tokenAddress);
        console.log("Raw token price:", tokenPrice.toString());
    
        console.log("Fetching token reserve...");
        const tokenReserve = await fairLaunchContract.getTokenReserve(tokenAddress);
        console.log("Token reserve:", tokenReserve.toString());
    
        console.log("Fetching ETH reserve...");
        const ethReserve = await fairLaunchContract.getEthReserve(tokenAddress);
        console.log("ETH reserve:", ethReserve.toString());
    
        const body = {
          type: "createToken",
          data: {
            tokenName: coinName,
            tokenSymbol: coinTicker,
            tokenAddress: tokenAddress,
            tokenImage: imageLink,
            marketcap: tokenMarketCap.toString(),
            price: ethers.formatEther(tokenPrice),
            virtualLP: initBuyAmount,
            creatorAddress: address,
            network: "Polygon",
            replies: 0,
            telegramLink,
            twitterLink,
            webLink,
            description: description,
            contracttype: contractType
          }
        };
    
        console.log("Body being sent to socket:", JSON.stringify(body, null, 2));
    
        socket.emit("message", JSON.stringify(body));
    
        setDeployModal(false);
        navigate('/my_Tokens');
      } catch (error) {
        console.error("Error deploying token:", error);
        if ((error as any).reason) {
          alert(`Error deploying token: ${(error as any).reason}`);
        } else {
          alert("Error deploying token. Please check console for details.");
        }
      } finally {
        setIsDeploying(false);
      }
    };

    const handleClose = () => {
        setDeployModal(false);
    };

    const uploadImageToIPFS = async (file: File) => {
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let data = new FormData();
        data.append('file', file);

        const headers = {
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataSecretApiKey,
            'Content-Type': 'multipart/form-data'
        };

        try {
            const response = await axios.post(url, data, { headers });
            return response.data;
        } catch (error) {
            console.error('Error uploading file: ', error);
            return null;
        }
    };

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setIsLoading(true);
            const file = event.target.files[0];
            const result = await uploadImageToIPFS(file);
            if (result) {
                setImageLink(result.IpfsHash);
            }
            setIsLoading(false);
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (input.length <= 140) {
            setDescription(input);
        }
    };

      useEffect(() => {
        const init = async () => {
            if (!walletProvider) return;

            try {
                const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider);
                const tempSigner = await ethersProvider.getSigner();
                setSigner(tempSigner);

                if (chainId !== 137) {
                    alert("Please connect to Polygon network");
                    return;
                }

                const realABI = await getContractABI();
                const tempFairLaunchContract = new Contract(contractAddress, realABI, tempSigner);
                setFairLaunchContract(tempFairLaunchContract);

                const nativeBalance = await tempSigner.provider.getBalance(tempSigner.address);
                setNativeBalanceString(ethers.formatEther(nativeBalance));

                console.log("Contract ABI:", JSON.stringify(tempFairLaunchContract.interface.fragments, null, 2));

                try {
                    const initialAmountUD60x18 = await tempFairLaunchContract.INITIAL_AMOUNT();
                    const initialAmountEther = ethers.formatUnits(initialAmountUD60x18, 18);
                    console.log("INITIAL_AMOUNT fetched from contract:", initialAmountEther);

                    if (initialAmountEther === '0.0' || parseFloat(initialAmountEther) === 0) {
                        console.warn("Fetched INITIAL_AMOUNT is 0.0. Using expected value:", EXPECTED_INITIAL_AMOUNT);
                        setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                        setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
                    } else {
                        console.log("Using fetched INITIAL_AMOUNT:", initialAmountEther);
                        setMinDeploymentAmount(initialAmountEther);
                        setInitBuyAmount(initialAmountEther);
                    }
                } catch (error) {
                    console.error("Error fetching INITIAL_AMOUNT:", error);
                    console.log("Using default INITIAL_AMOUNT:", EXPECTED_INITIAL_AMOUNT);
                    setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                    setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
                }

            } catch (error) {
                console.error("Error in init:", error);
                setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
            }
        };
        init();
    }, [chainId, address, walletProvider, contractAddress, pricingMethod]);

    const handlePricingMethodChange = (event: SelectChangeEvent<unknown>) => {
      const randomType = Math.floor(Math.random() * 24);
      const selectedAddress = POLYGON_RANDOM_CONTRACT_ADDRESSES[randomType % POLYGON_RANDOM_CONTRACT_ADDRESSES.length];
      const contractName = contractNameMap[selectedAddress] || 'FairLaunchFactory';
      
      console.log("=== Pricing Method Change ===");
      console.log("Generated Random Type:", randomType);
      console.log("Selected Contract Address:", selectedAddress);
      console.log("Using Contract:", contractName);
      console.log("Using ABI file:", `${contractName}.json`);
      
      setContractType(randomType);
      setContractAddress(selectedAddress);
  };

      return (
        <Win95Box>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6} mx='auto'>
                    <Win95Box sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
                        <Win95Typography variant="h4" gutterBottom>Create a New Memecoin on Polygon</Win95Typography>
                        <Win95FormControl fullWidth margin="normal">
                            <InputLabel id="pricing-method-label">Pricing Method</InputLabel>
                            <Win95Select
                                labelId="pricing-method-label"
                                value={pricingMethod}
                                onChange={handlePricingMethodChange}
                                label="Pricing Method"
                            >
                                <MenuItem value="random">Random</MenuItem>
                            </Win95Select>
                        </Win95FormControl>
                        <Win95TextField label="Name" variant="outlined" fullWidth value={coinName} onChange={(e) => setCoinName(e.target.value)} margin="normal" />
                        <Win95TextField label="Ticker" variant="outlined" fullWidth value={coinTicker} onChange={(e) => setCoinTicker(e.target.value)} margin="normal" />
                        <Win95TextField 
                            label="Description" 
                            variant="outlined" 
                            multiline 
                            rows={4} 
                            fullWidth 
                            value={description} 
                            onChange={handleDescriptionChange} 
                            margin="normal"
                            inputProps={{ maxLength: 140 }}
                            helperText={`${description.length}/140 characters`}
                        />
                        <Win95Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            sx={{ marginTop: 2, marginBottom: 2 }}
                        >
                            Upload Image
                            <input type="file" hidden onChange={onFileChange} accept="image/*" />
                        </Win95Button>
                        {imageLink && <Win95Typography>Image uploaded successfully</Win95Typography>}
                        {isLoading && <Win95Typography>Uploading image...</Win95Typography>}
                        <Win95TextField label="Telegram Link" variant="outlined" fullWidth value={telegramLink} onChange={(e) => setTelegramLink(e.target.value)} margin="normal" />
                        <Win95TextField label="Twitter Link" variant="outlined" fullWidth value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} margin="normal" />
                        <Win95TextField label="Website Link" variant="outlined" fullWidth value={webLink} onChange={(e) => setWebLink(e.target.value)} margin="normal" />
                        <Win95TextField 
                            label="Deploy Fee (POL)" 
                            variant="outlined" 
                            fullWidth 
                            value={initBuyAmount} 
                            onChange={(e) => setInitBuyAmount(e.target.value)} 
                            margin="normal" 
                            type="number"
                            helperText={`Minimum: ${minDeploymentAmount} POL`}
                        />
                        <Win95Button variant="contained" color="primary" fullWidth onClick={handleClickOpen} sx={{ marginTop: 2 }}>
                            {isConnected ? "Deploy Memecoin" : "Connect Wallet"}
                        </Win95Button>
                    </Win95Box>
                </Grid2>
            </Grid2>
            <Dialog 
                open={deployModal} 
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    style: {
                        backgroundColor: windows95Theme.palette.background.paper,
                        color: windows95Theme.palette.text.primary,
                        border: `1px solid ${windows95Theme.palette.primary.main}`,
                        borderRadius: 0,
                    },
                }}
            >
                <DialogTitle>
                    <Win95Typography>Confirm Deployment</Win95Typography>
                </DialogTitle>
                <DialogContent>
                    {isDeploying ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Box sx={{ width: '100%', mb: 2 }}>
                                <AnimatedWin95ProgressBar />
                            </Box>
                            <Win95Typography>Deploying your token. Please wait...</Win95Typography>
                        </Box>
                    ) : (
                        <>
                            <Win95Typography>
                                Are you sure you want to deploy your token with the following details?
                            </Win95Typography>
                            <Win95Card sx={{ mt: 2 }}>
                                <Win95CardContent>
                                    <Win95Typography>Name: {coinName}</Win95Typography>
                                    <Win95Typography>Ticker: {coinTicker}</Win95Typography>
                                    <Win95Typography>Deploy Fee: {initBuyAmount} POL</Win95Typography>
                                    <Win95Typography>Your Balance: {nativeBalanceString} POL</Win95Typography>
                                </Win95CardContent>
                            </Win95Card>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Win95Button onClick={handleClose} disabled={isDeploying}>
                        Cancel
                    </Win95Button>
                    <Win95Button onClick={tokenDeploy} disabled={isDeploying}>
                        {isDeploying ? 'Deploying...' : 'Confirm & Deploy'}
                    </Win95Button>
                </DialogActions>
            </Dialog>
        </Win95Box>
    );
}