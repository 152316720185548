import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { createChart, IChartApi, ISeriesApi, ColorType, Time, UTCTimestamp, CandlestickData, WhitespaceData, HistogramData } from 'lightweight-charts';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, ethers } from 'ethers';
import axios from "axios";
import { getContractAddress, getContractABI, getHref } from "../utils/contractUtils";
import BigNumber from 'bignumber.js';
import erc20ABI from "../abis/erc20ABI.json";
import { 
  Box, Button, TextField, Typography, TypographyProps, Dialog, DialogTitle, 
  DialogContent, DialogActions, Card, CardContent, Tabs, Tab, Link, CircularProgress,
  Slider, ThemeProvider, createTheme, CssBaseline, useMediaQuery, Container, useTheme, LinearProgress
} from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CustomPaginationActionsTable from "../components/table";
import { socket } from "../utils/socket";
import Chart from '../Chart';
import { color } from "@amcharts/amcharts5";
import windows95Theme from '../windows95Theme';

const TOKEN_DECIMALS = 18;
const MIN_PURCHASE_AMOUNT = ethers.parseEther("0.001");
const TOTAL_SUPPLY = new BigNumber(10).pow(9); // 1 billion tokens
const LAUNCH_THRESHOLD = new BigNumber(5); // 5 MATIC
const slippageValues = [0, 0.001, 0.005, 0.01, 0.02, 0.05];

interface ChartProps {
  data: {
    price: CandlestickData<Time>[];
    volume: HistogramData<Time>[];
  };
  latestPrice: string;
  contractType: number;
}

interface TokenDetails {
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  tokenImage: string;
  marketcap: string;
  network: string;
  creatorAddress: string;
  ethReserve: string;
  tokenReserve: string;
  tokenPrice: string;
  percentageSold: string;
  trades: string;
  tokensRemaining: string;
  isLaunched: boolean;
  //targetMarketCap: string;
  contracttype: number;
}

interface TradeData {
  id: number;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenImage: string;
  date: string | number;
  type: "BUY" | "SELL";
  ethAmount: number | null;
  tokenAmount: string;
  txHash: string;
  swapperAddress: string;
  tokenPrice: string;
  network: string;
}

interface ChartDataPoint {
  date: number;
  tokenPrice: string;
  volume: string;
}

const toBigNumber = (value: string | number) => new BigNumber(value);
const formatForDisplay = (value: BigNumber, decimals: number = 6) => value.toFixed(decimals);
const toMatic = (wei: BigNumber): BigNumber => wei.dividedBy(new BigNumber(10).pow(18));
const toWei = (matic: BigNumber): BigNumber => matic.times(new BigNumber(10).pow(18));
const toTokenAmount = (amount: BigNumber): BigNumber => amount.dividedBy(new BigNumber(10).pow(TOKEN_DECIMALS));
const toTokenWei = (amount: BigNumber): BigNumber => amount.times(new BigNumber(10).pow(TOKEN_DECIMALS));

// Styled components (update to use theme)
const Win95Card = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 0,
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
}));

const Win95Typography = styled(Typography)<{ component?: React.ElementType }>(({ theme }) => ({
  fontFamily: '"MS Sans Serif", Arial, sans-serif',
  color: theme.palette.text.primary,
}));

const Win95Button = styled(Button)(({ theme }) => ({
  fontFamily: '"MS Sans Serif", Arial, sans-serif',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 0,
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    boxShadow: 'inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey',
  },
}));

const Win95TextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
  '& .MuiInputLabel-root': {
    fontFamily: '"MS Sans Serif", Arial, sans-serif',
  },
  '& .MuiInputBase-input': {
    fontFamily: '"MS Sans Serif", Arial, sans-serif',
  },
}));

const Win95LinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 0,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.paper,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Win95Slider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontFamily: '"MS Sans Serif", Arial, sans-serif',
  },
}));

const Win95ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 0,
  border: `2px solid ${theme.palette.grey[700]}`,
  backgroundColor: theme.palette.background.paper,
  '& .MuiLinearProgress-bar': {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));

// Add this new component for an animated progress bar
const AnimatedWin95ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Win95ProgressBar variant="determinate" value={progress} />;
};

const getPricingMethodString = (contractType: number): string => {
  switch (contractType) {
    case 0:
      return 'reserveRatio'; // Reserve Ratio (default)
    case 1:
      return 'linear';
    case 2:
      return 'logarithmic';
    case 3:
      return 'exponential';
    default:
      console.warn(`Unknown contract type: ${contractType}, defaulting to Reserve Ratio`);
      return '';
  }
};

const formatPrice = (price: number) => {
  if (isNaN(price) || price === 0) return '0';
  
  const priceString = price.toFixed(18);
  const [integerPart, decimalPart] = priceString.split('.');
  
  if (integerPart !== '0') {
    return price.toFixed(4);
  }

  const leadingZeros = decimalPart.match(/^0+/)?.[0].length || 0;
  const significantDigits = decimalPart.slice(leadingZeros, leadingZeros + 4);

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      0.0
      <sub style={{ fontSize: '0.7em', verticalAlign: 'bottom' }}>{leadingZeros}</sub>
      {significantDigits}
    </span>
  );
};

const DetailPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tokenData, setTokenData] = useState<TokenDetails | null>(null);
  const [chartData, setChartData] = useState<ChartProps['data']>({ price: [], volume: [] });
  const [tradeData, setTradeData] = useState<TradeData[]>([]);
  const [chatData, setChatData] = useState<any[]>([]);
  const [holders, setHolders] = useState<any[]>([]);
  const [isLiveUpdating, setIsLiveUpdating] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [amount, setAmount] = useState<string>('');
  const [type, setType] = useState<"BUY" | "SELL">("BUY");
  const [calculatedCost, setCalculatedCost] = useState<string>('0');
  const [userTokenBalance, setUserTokenBalance] = useState<BigNumber>(new BigNumber(0));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState<{
    tokenAmount: string;
    maticCost: string;
  } | null>(null);
  const [transactionResult, setTransactionResult] = useState<string | null>(null);
  const [transactionError, setTransactionError] = useState<string | null>(null);
  const [launched, setLaunched] = useState(false);
  const [currentMarketCap, setCurrentMarketCap] = useState<BigNumber>(new BigNumber(0));
  const [percentageSold, setPercentageSold] = useState<number>(0);
  const [tokensRemaining, setTokensRemaining] = useState<BigNumber>(TOTAL_SUPPLY);
  const [isLaunchStatusLoading, setIsLaunchStatusLoading] = useState(true);
  const [tokenAmountError, setTokenAmountError] = useState<string | null>(null);
  const [showBuyRemaining, setShowBuyRemaining] = useState(false);
  const [slippage, setSlippage] = useState<number>(0);

  const { chain, id } = useParams<{ chain: string; id: string }>();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candlestickSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);

  const theme = windows95Theme;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchTokenData = useCallback(async (tokenAddress: string, network: string, contractType: number): Promise<TokenDetails> => {
    const provider = new ethers.JsonRpcProvider(getRpcUrl(network));
    const pricingMethod = getPricingMethodString(contractType);
    const contractABI = await getContractABI();
    const contractAddress = getContractAddress();
    const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
    try {
      const totalSupplyWei = BigInt(ethers.parseUnits(TOTAL_SUPPLY.toString(), 18).toString());
      const totalSupplyEther = TOTAL_SUPPLY;
  
      const tokensSoldWei = await contract.getTokensSold(tokenAddress);
      const tokensSold = new BigNumber(ethers.formatEther(tokensSoldWei));
  
      const priceWei = await contract.getPrice(tokenAddress);
      const price = new BigNumber(ethers.formatEther(priceWei));
  
      const tokenReserve = totalSupplyEther.minus(tokensSold);
      const trades = Number(await contract.getTokenTrades(tokenAddress));
      
      const marketCapWei = await contract.getTokenMarketCap(tokenAddress);
      const marketcap = new BigNumber(ethers.formatEther(marketCapWei));
  
      const percentageSold = tokensSold.div(totalSupplyEther).times(100);
      const tokensRemaining = totalSupplyEther.minus(tokensSold);
  
      const isLaunched = percentageSold.isGreaterThanOrEqualTo(70);
  
      console.log("Contract type:", contractType);
      console.log("Contract address:", contractAddress);
      console.log("Token address:", tokenAddress);
      console.log("Raw price (Wei):", priceWei.toString());
      console.log("Formatted price (Ether):", price.toString());
      console.log("Raw market cap (Wei):", marketCapWei.toString());
      console.log("Formatted market cap (Ether):", marketcap.toString());
  
      return {
        tokenAddress,
        tokenName: "", // Fetch this from your API
        tokenSymbol: "", // Fetch this from your API
        tokenImage: "", // Fetch this from your API
        marketcap: marketcap.toString(),
        network,
        creatorAddress: "", // Fetch this from your API
        ethReserve: ethers.formatEther(await contract.getEthReserve(tokenAddress)),
        tokenReserve: tokenReserve.toString(),
        tokenPrice: price.toString(),
        percentageSold: percentageSold.toString(),
        trades: trades.toString(),
        tokensRemaining: tokensRemaining.toString(),
        isLaunched: isLaunched,
        contracttype: contractType
      };
    } catch (error) {
      console.error("Error fetching token data:", error, "for token:", tokenAddress);
      throw error;
    }
  }, []);

  const formatPriceByMethod = (price: string, contractType: number) => {
    const priceNum = new BigNumber(price);
    console.log("Formatting price:", price, "for contract type:", contractType);
    return formatPrice(priceNum.toNumber());
  };

  const setupLiveUpdates = useCallback(() => {
    if (!chain || !id) return;
  
    socket.on('connect', () => {
      console.log('WebSocket connected');
      socket.emit('subscribe', { chain, tokenId: id });
    });
  
    socket.on('tokenUpdate', (updatedData) => {
      setTokenData(prevData => ({
        ...prevData,
        ...updatedData
      } as TokenDetails));
      fetchPriceAndStatus(updatedData);
    });
  
    socket.on('PriceUpdated', (event) => {
      const newPrice = ethers.formatEther(event.newPrice);
      setTokenData(prevData => ({
        ...prevData,
        tokenPrice: newPrice
      } as TokenDetails));
    });
  
    setIsLiveUpdating(true);
  
    return () => {
      socket.off('tokenUpdate');
      socket.off('tradeUpdate');
      socket.off('PriceUpdated');
      socket.emit('unsubscribe', { chain, tokenId: id });
    };
  }, [chain, id]);

  const fetchPriceAndStatus = useCallback(async (tokenData: TokenDetails) => {
    try {
      const updatedData = await fetchTokenData(tokenData.tokenAddress, tokenData.network, tokenData.contracttype);
      
      setTokenData(prevData => {
        if (prevData === null) return updatedData;
        if (JSON.stringify(prevData) !== JSON.stringify(updatedData)) {
          return {
            ...prevData,
            ...updatedData,
            tokenName: prevData.tokenName,
            tokenSymbol: prevData.tokenSymbol,
            tokenImage: prevData.tokenImage,
            creatorAddress: prevData.creatorAddress
          };
        }
        return prevData;
      });
  
      setCurrentMarketCap(new BigNumber(updatedData.marketcap));
      setLaunched(updatedData.isLaunched);
      setTokensRemaining(new BigNumber(updatedData.tokensRemaining));
      setPercentageSold(parseFloat(updatedData.percentageSold));
    } catch (error) {
      console.error("Error fetching price and status:", error);
    } finally {
      setIsLaunchStatusLoading(false);
    }
  }, [fetchTokenData]);

  const updatePrice = useCallback((newPrice: string) => {
    setTokenData(prevData => {
      if (prevData === null) return null;
      const updatedData = {
        ...prevData,
        tokenPrice: newPrice,
        marketcap: new BigNumber(newPrice).times(TOTAL_SUPPLY).toString()
      };
      return updatedData;
    });
  }, []);

  const processChartData = (rawData: ChartDataPoint[], contractType: number): ChartProps['data'] => {
    console.log("Raw Chart Data:", rawData);
    const priceData: CandlestickData<Time>[] = [];
    const volumeData: HistogramData<Time>[] = [];
    const aggregatedData: { [key: number]: CandlestickData<Time> & { volume: number, count: number } } = {};
  
    const INTERVAL = 5 * 60;
    const EMBELLISH_FACTOR = 0.01;
  
    const scalePrice = (price: string): number => {
      const parsedPrice = new BigNumber(price);
      if (parsedPrice.isNaN()) {
        console.warn(`Invalid price detected: ${price}`);
        return 0; // or some default value
      }
      if (contractType === 3) { // Exponential pricing
        return parsedPrice.times(1e18).toNumber();
      } else {
        return parsedPrice.toNumber();
      }
    };
  
    rawData.forEach((item) => {
      const timestamp = Math.floor(item.date / INTERVAL) * INTERVAL;
      const price = scalePrice(item.tokenPrice);
      const volume = parseFloat(item.volume) || 0; // Use 0 if NaN
  
      if (!aggregatedData[timestamp]) {
        aggregatedData[timestamp] = {
          time: timestamp as Time,
          open: price,
          high: price,
          low: price,
          close: price,
          volume: volume,
          count: 1
        };
      } else {
        const candle = aggregatedData[timestamp];
        candle.high = Math.max(candle.high, price);
        candle.low = Math.min(candle.low, price);
        candle.close = price;
        candle.volume += volume;
        candle.count += 1;
      }
    });
  
    Object.values(aggregatedData).forEach((candle) => {
      if (candle.count === 1) {
        const variation = candle.open * EMBELLISH_FACTOR;
        candle.high = candle.open + variation;
        candle.low = candle.open - variation;
        candle.close = candle.open + (variation / 2);
      }
  
      priceData.push({
        time: candle.time,
        open: candle.open,
        high: candle.high,
        low: candle.low,
        close: candle.close
      });
  
      volumeData.push({
        time: candle.time,
        value: candle.volume,
        color: candle.close >= candle.open ? '#26a69a' : '#ef5350'
      });
    });
  
    return { price: priceData, volume: volumeData };
  };

  const fetchInitialData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!chain || !id) {
        throw new Error("Missing chain or token ID in URL");
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}/tokens/${chain}/${id}`;
      const response = await axios.get(apiUrl);
      
      console.log("API Response:", response.data);
  
      if (response.data && (response.data.tokenDetails || response.data.tokenDetils)) {
        const initialTokenData = response.data.tokenDetails || response.data.tokenDetils;
        console.log("Initial Token Data:", initialTokenData);
  
        if (!initialTokenData.tokenAddress || !initialTokenData.network || initialTokenData.contracttype === undefined) {
          throw new Error("Incomplete token data received from the server");
        }
        
        setTokenData(initialTokenData);
        
        const updatedData = await fetchTokenData(initialTokenData.tokenAddress, initialTokenData.network, initialTokenData.contracttype);
        console.log("Updated Token Data:", updatedData);
        
        setTokenData(prevData => ({
          ...prevData,
          ...updatedData
        }));
        
        const processedChartData = processChartData(response.data.chartList || [], initialTokenData.contracttype);
        console.log("Processed Chart Data:", processedChartData);
        setChartData(processedChartData);
  
        setChatData(response.data.chatList || []);
        setHolders(response.data.holders || []);
  
        await fetchPriceAndStatus(initialTokenData);
      } else {
        throw new Error("Invalid data structure received from the server");
      }
    } catch (error) {
      console.error("Error fetching token details:", error);
      setError(error instanceof Error ? error.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [chain, id, fetchPriceAndStatus]);

  const updateUserTokenBalance = async () => {
    if (tokenData && address && walletProvider) {
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const tokenContract = new ethers.Contract(tokenData.tokenAddress, erc20ABI, signer);
        const balanceWei = await tokenContract.balanceOf(address);
        const balanceInTokens = toTokenAmount(new BigNumber(balanceWei.toString()));
        setUserTokenBalance(balanceInTokens);
      } catch (error) {
        console.error("Error updating user token balance:", error);
      }
    }
  };

  const handleAmountChange = async (inputValue: string) => {
    if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
      setAmount(inputValue);
      if (tokenData?.tokenAddress && tokenData?.network && tokenData?.contracttype !== undefined) {
        const tokenAmount = toBigNumber(inputValue || '0');
        let calculatedCostMatic: BigNumber;
  
        try {
          const provider = new ethers.JsonRpcProvider(getRpcUrl(tokenData.network));
          const pricingMethod = getPricingMethodString(tokenData.contracttype);
          const contractABI = await getContractABI();
          const contractAddress = getContractAddress();
          const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
          console.log("Calculating cost for contract type:", tokenData.contracttype);
  
          if (type === "BUY") {
            const costWei = await contract.getTokenAmount(tokenData.tokenAddress, ethers.parseEther(tokenAmount.toString()));
            calculatedCostMatic = new BigNumber(ethers.formatEther(costWei));
          } else {
            const priceWei = await contract.getPrice(tokenData.tokenAddress);
            const price = new BigNumber(ethers.formatEther(priceWei));
            calculatedCostMatic = tokenAmount.times(price);
          }
  
          console.log("Calculated cost (POL):", calculatedCostMatic.toString());
  
          setConfirmationDetails({
            tokenAmount: tokenAmount.toString(),
            maticCost: calculatedCostMatic.toString()
          });
  
          if (type === "BUY" && tokenAmount.isGreaterThan(toBigNumber(tokenData.tokensRemaining))) {
            setTokenAmountError("Requested amount exceeds available tokens.");
          } else if (type === "SELL" && tokenAmount.isGreaterThan(userTokenBalance)) {
            setTokenAmountError("Requested amount exceeds your token balance.");
          } else {
            setTokenAmountError(null);
          }
        } catch (error) {
          console.error("Error calculating price:", error);
          setCalculatedCost('0');
          setTokenAmountError("Error calculating price. Please try again.");
        }
      }
    }
  };

  const handleBuySell = async () => {
    setTransactionError(null);
    setTransactionResult(null);
  
    if (!isConnected || !tokenData) {
      setTransactionError("Please connect your wallet and try again.");
      await open();
      return;
    }
  
    if (chainId !== getChainId(tokenData.network)) {
      setTransactionError(`Please switch to the ${tokenData.network} network and try again.`);
      return;
    }

    if (type === "BUY" && parseFloat(tokenData.percentageSold) >= 70) {
      setTransactionError("Token sale has ended. You can no longer buy tokens.");
      return;
    }
  
    const tokenAmount = toBigNumber(amount || '0');
    const tokenPriceInMatic = toBigNumber(tokenData.tokenPrice);
    let maticCost: BigNumber;
    let adjustedAmount: BigNumber;
  
    if (type === "BUY") {
      maticCost = tokenAmount.times(tokenPriceInMatic);
      adjustedAmount = maticCost.times(1.03).times(1 + slippage);
    } else {
      maticCost = tokenAmount.times(tokenPriceInMatic);
      adjustedAmount = tokenAmount.times(0.97).times(1 - slippage);
      if (adjustedAmount.isGreaterThan(userTokenBalance)) {
        setTransactionError("Insufficient token balance for this sale.");
        return;
      }
    }
  
    setConfirmationDetails({
      tokenAmount: type === "BUY" ? tokenAmount.toString() : adjustedAmount.toString(),
      maticCost: type === "BUY" ? adjustedAmount.toString() : maticCost.toString()
    });
  
    setShowConfirmation(true);
  };

  const updateTradeData = useCallback((newTrade: TradeData) => {
    setTradeData(prevTrades => {
      const updatedTrades = [newTrade, ...prevTrades];
      return updatedTrades.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
    });
  }, []);

  const updateTokenDetails = async () => {
    if (!tokenData) return;
    
    try {
      const updatedData = await fetchTokenData(tokenData.tokenAddress, tokenData.network, tokenData.contracttype);
      setTokenData(prevData => ({
        ...prevData,
        ...updatedData,
        tokenName: prevData?.tokenName || "",
        tokenSymbol: prevData?.tokenSymbol || "",
        tokenImage: prevData?.tokenImage || "",
        creatorAddress: prevData?.creatorAddress || ""
      }));
      setCurrentMarketCap(new BigNumber(updatedData.marketcap));
      setLaunched(updatedData.isLaunched);
      setTokensRemaining(new BigNumber(updatedData.tokensRemaining));
      setPercentageSold(parseFloat(updatedData.percentageSold));

      socket.emit('updateTokenData', {
        tokenAddress: tokenData.tokenAddress,
        network: tokenData.network,
        updatedData: {
          marketcap: updatedData.marketcap,
          tokenPrice: updatedData.tokenPrice,
          tokensRemaining: updatedData.tokensRemaining,
          percentageSold: updatedData.percentageSold,
          isLaunched: updatedData.isLaunched,
        }
      });
    } catch (error) {
      console.error("Error updating token details:", error);
    }
  };

  const handleConfirmedTransaction = async () => {
    setTransactionError(null);
    setTransactionResult(null);
    setIsTransactionInProgress(true);
  
    if (!walletProvider || !address || !tokenData || !confirmationDetails) {
      setTransactionError("Missing required information. Please try again.");
      setIsTransactionInProgress(false);
      return;
    }
  
    try {
      console.log("Starting transaction process...");
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      console.log("Signer obtained:", signer.address);
  
      const pricingMethod = getPricingMethodString(tokenData.contracttype);
      console.log("Pricing method:", pricingMethod);
      const contractAddress = getContractAddress();
      console.log("Contract address:", contractAddress);
      const contractABI = await getContractABI();
      console.log("Contract ABI:", contractABI);
  
      if (!contractABI) {
        throw new Error(`Failed to load ABI for pricing method: ${pricingMethod}`);
      }
  
      const contract = new Contract(contractAddress, contractABI, signer);
      console.log("Contract instance created");
  
      const tokenAmount = ethers.parseUnits(confirmationDetails.tokenAmount, TOKEN_DECIMALS);
      const maticAmount = ethers.parseEther(confirmationDetails.maticCost);
      
      console.log("Token amount:", tokenAmount.toString());
      console.log("POL amount:", maticAmount.toString());
  
      let transaction;
      if (type === "BUY") {
        console.log("Initiating BUY transaction...");
        transaction = await contract.buyTokens(tokenData.tokenAddress, { value: maticAmount });
      } else {
        console.log("Initiating SELL transaction...");
        transaction = await contract.sellTokens(tokenData.tokenAddress, tokenAmount);
      }
  
      console.log("Transaction sent:", transaction.hash);
      const receipt = await transaction.wait();
      console.log("Transaction confirmed:", receipt.transactionHash);
  
      setTransactionResult(`Transaction successful!`);
      
      // Log the transaction
      const newTrade = await logTransaction(receipt.transactionHash, confirmationDetails.maticCost, confirmationDetails.tokenAmount);
      updateTradeData(newTrade);
  
      // Update token details and user balance
      await updateTokenDetails();
      await updateUserTokenBalance();
  
    } catch (error) {
      console.error("Transaction failed:", error);
      setTransactionError(error instanceof Error ? error.message : "Transaction failed. Please try again.");
    } finally {
      setIsTransactionInProgress(false);
      setShowConfirmation(false);
    }
  };

  const getTrades = async () => {
    const body = {
      tokenAddress: id,
      network: chain
    }
    try {
      const response = await axios.post<TradeData[]>(`${process.env.REACT_APP_API_URL}/trades`, body);
      console.log("Raw trade data (first item):", response.data[0]);
      const sortedTradeData = response.data.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setTradeData(sortedTradeData);
    } catch (error) {
      console.error("Error fetching trade data:", error);
    }
  };

  const logTransaction = async (txHash: string, maticAmount: string, tokenAmount: string): Promise<TradeData> => {
    if (!tokenData) {
      throw new Error("Cannot log transaction: tokenData is null");
    }

    const newTrade: TradeData = {
      id: Date.now(),
      tokenName: tokenData.tokenName,
      tokenSymbol: tokenData.tokenSymbol,
      tokenAddress: tokenData.tokenAddress,
      tokenImage: tokenData.tokenImage,
      swapperAddress: address || "",
      type: type,
      ethAmount: maticAmount ? parseFloat(maticAmount) : null,
      tokenAmount: tokenAmount,
      network: chain || "",
      txHash: txHash,
      tokenPrice: tokenData.tokenPrice,
      date: new Date().toISOString()
    };
    
    socket.emit('message', JSON.stringify({
      type: "swap",
      data: newTrade
    }));

    console.log("Transaction logged via socket:", newTrade);
    return newTrade;
  };

  const formatLargeNumber = (num: BigNumber | null | undefined): string => {
    if (num == null) return 'N/A';
    if (num.isGreaterThanOrEqualTo(1e9)) return num.div(1e9).toFixed(3) + 'B';
    if (num.isGreaterThanOrEqualTo(1e6)) return num.div(1e6).toFixed(3) + 'M';
    if (num.isGreaterThanOrEqualTo(1e3)) return num.div(1e3).toFixed(3) + 'K';
    return num.toFixed(3);
  };

  const formatPriceForDisplay = (price: string | null): { 
    integerPart: string, 
    zeroCount: number, 
    significantDecimalPart: string 
  } => {
    if (price === null) return { integerPart: "N/A", zeroCount: 0, significantDecimalPart: "" };
    const priceNumber = new BigNumber(price);
    if (priceNumber.isEqualTo('0.0000000001')) {
      return { integerPart: "0", zeroCount: 8, significantDecimalPart: "01" };
    }
    const [integerPart, decimalPart = ""] = price.split('.');
    const leadingZeros = decimalPart.match(/^0*/)?.[0].length ?? 0;
    const significantDecimalPart = decimalPart.slice(leadingZeros);
    return {
      integerPart: integerPart === "0" ? "0" : integerPart,
      zeroCount: leadingZeros,
      significantDecimalPart: significantDecimalPart || "0"
    };
  };

  const getRpcUrl = (network: string): string => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    if (!infuraProjectId) {
      console.error("Infura Project ID is not set");
      return "";
    }
    switch(network) {
      case "Polygon":
        return `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`;
      default:
        console.error("Unsupported network:", network);
        return "";
    }
  };
  /*const getRpcUrl = (network: string): string => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    switch(network) {
      case "Polygon":
        // Use an array of RPC URLs
        const polygonRPCs = [
          `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`,
          "https://polygon-rpc.com",
          "https://rpc-mainnet.matic.network",
          "https://matic-mainnet.chainstacklabs.com"
        ];
        // Randomly select an RPC URL to balance the load
        return polygonRPCs[Math.floor(Math.random() * polygonRPCs.length)];
      default:
        console.error("Unsupported network:", network);
        return "";
    }
  };*/

  const getChainId = (network: string): number => {
    return 137; // Always return 137 for Polygon mainnet
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [comment, setComment] = useState("");

  const handleOpenCommentDialog = () => {
    setOpenCommentDialog(true);
  };

  const handleCloseCommentDialog = () => {
    setOpenCommentDialog(false);
  };

  const handlePostComment = async () => {
    if (!id || !address || !comment || !chain) return;
    const body = {
      tokenAddress: id,
      replyAddress: address,
      comment: comment,
      network: chain
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chats/reply`, body);
      setChatData(response.data);
    } catch (error) {
      console.error("Error posting comment:", error);
    }
    setOpenCommentDialog(false);
    setComment("");
  };

  const handleBuyRemaining = () => {
    const remainingAmount = tokensRemaining.toString();
    setAmount(remainingAmount);
    handleAmountChange(remainingAmount);
  };

  const slippageMarks = slippageValues.map((value, index) => ({
    value: index,
    label: `${(value * 100).toFixed(1)}%`,
  }));

  const handleSlippageChange = (event: Event, newValue: number | number[]) => {
    setSlippage(slippageValues[newValue as number]);
  };

  const dateFormat = (date: string | number): string => {
    let dateObj: Date;
    const currentDate = new Date();
    
    if (typeof date === 'string') {
        dateObj = new Date(date);
    } else if (typeof date === 'number') {
        dateObj = new Date(date * 1000);
        
        if (dateObj.getTime() - currentDate.getTime() > 24 * 60 * 60 * 1000) {
            console.warn(`Future date detected: ${dateObj.toISOString()}. Using current date instead.`);
            dateObj = currentDate;
        }
    } else {
        return 'Invalid date';
    }
    
    if (isNaN(dateObj.getTime())) {
        return 'Invalid date';
    }
    
    return dateObj.toLocaleString();
  };

  useEffect(() => {
    fetchInitialData();
    const cleanup = setupLiveUpdates();
    return () => {
      if (cleanup) cleanup();
    };
  }, [fetchInitialData, setupLiveUpdates]);

  useEffect(() => {
    updateUserTokenBalance();
  }, [address, walletProvider, tokenData]);

  useEffect(() => {
    socket.on('chartUpdate', (newDataPoint: ChartDataPoint) => {
      setChartData((prevData: ChartProps['data']) => {
        const newPrice = {
          time: newDataPoint.date as Time,
          open: parseFloat(newDataPoint.tokenPrice) || 0,
          high: parseFloat(newDataPoint.tokenPrice) || 0,
          low: parseFloat(newDataPoint.tokenPrice) || 0,
          close: parseFloat(newDataPoint.tokenPrice) || 0,
        };
        const newVolume = {
          time: newDataPoint.date as Time,
          value: parseFloat(newDataPoint.volume) || 0,
          color: '#26a69a',
        };
        return {
          price: [...prevData.price, newPrice],
          volume: [...prevData.volume, newVolume],
        };
      });
    });
  
    return () => {
      socket.off('chartUpdate');
    };
  }, []);

  useEffect(() => {
    socket.on('tradeUpdate', (newTrade: TradeData) => {
      updateTradeData(newTrade);
    });

    return () => {
      socket.off('tradeUpdate');
    };
  }, [updateTradeData]);

  useEffect(() => {
    if (!tokenData || !tokenData.tokenAddress || !tokenData.network) return;
  
    let intervalId: NodeJS.Timeout;
  
    const setupPricePolling = async () => {
      try {
        const provider = new ethers.JsonRpcProvider(getRpcUrl(tokenData.network));
        const pricingMethod = getPricingMethodString(tokenData.contracttype);
        const contractAddress = getContractAddress();
        const contractABI = await getContractABI();
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
        const pollPrice = async () => {
          try {
            const newPrice = await contract.getPrice(tokenData.tokenAddress);
            updatePrice(ethers.formatEther(newPrice.toString()));
          } catch (error) {
            console.error("Error polling price:", error);
          }
        };
  
        // Initial poll
        await pollPrice();
  
        // Set up interval for polling
        intervalId = setInterval(pollPrice, 10000);
      } catch (error) {
        console.error("Error setting up price polling:", error);
      }
    };
  
    setupPricePolling();
  
    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [tokenData?.tokenAddress, tokenData?.network, tokenData?.contracttype, updatePrice]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTokenDetails();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [updateTokenDetails]);

  useEffect(() => {
    getTrades();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">Error: {error}</Typography>
      </Box>
    );
  }

  if (!tokenData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={windows95Theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 xs={12} md={8}>
            <Win95Card>
              <CardContent>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'row', 
                  alignItems: 'center', 
                  mb: 2 
                }}>
                  <Link
                    href={`https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${tokenData.tokenImage}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'block',
                      width: { xs: '60px', sm: '80px' },
                      height: { xs: '60px', sm: '80px' },
                      marginRight: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <img 
                      src={tokenData.tokenImage 
                        ? `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${tokenData.tokenImage}`
                        : `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/QmPQVWHogwehXJp6kr7EyL2k1fa2q4EsFbz7VKsDqmwViP`
                      }
                      alt={`${tokenData.tokenName} logo`} 
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    />
                  </Link>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Win95Typography variant={isSmallScreen ? "subtitle1" : "h6"}>
                      {tokenData.tokenName}
                    </Win95Typography>
                    <Win95Typography variant="body2">
                      {tokenData.tokenSymbol}
                    </Win95Typography>
                  </Box>
                </Box>
  
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                  <Win95Typography variant={isSmallScreen ? "h6" : "h5"}>
                    ${formatPriceByMethod(tokenData.tokenPrice, tokenData.contracttype)} POL
                  </Win95Typography>
                </Box>
  
                <Win95Typography variant="body2" sx={{ mb: 2, textAlign: 'center', wordBreak: 'break-all' }}>
                  Token Address:{' '}
                  <Link
                    href={getHref(tokenData.network, tokenData.tokenAddress)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tokenData.tokenAddress}
                  </Link>
                </Win95Typography>
  
                <Win95Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                  Launch Status:{' '}
                  <Win95Typography component="span">
                    {isLaunchStatusLoading ? "Loading..." : (tokenData.isLaunched ? "Launched" : "Pre-launch")}
                  </Win95Typography>
                </Win95Typography>
  
                {!isLaunchStatusLoading && !tokenData.isLaunched && (
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Win95Typography variant="body2" gutterBottom>Progress to launch:</Win95Typography>
                    <Win95LinearProgress 
                      variant="determinate" 
                      value={Math.min((parseFloat(tokenData.percentageSold) / 70) * 100, 100)} 
                    />
                    <Win95Typography variant="body2" gutterBottom>
                    Tokens Sold: {(parseFloat(tokenData.percentageSold) / 0.7).toFixed(2)}%
                    </Win95Typography>
                  </Box>
                )}
  
                <Chart 
                  data={chartData} 
                  latestPrice={tokenData.tokenPrice}
                  contractType={tokenData.contracttype}
                />
                
                {(chartData.price.length === 0 || chartData.volume.length === 0) && (
                  <Win95Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                    No chart data available. The chart will appear when price data is available.
                  </Win95Typography>
                )}
              </CardContent>
            </Win95Card>
  
            <Win95Card sx={{ mt: 2 }}>
              <CardContent>
                <Tabs 
                  value={tabValue} 
                  onChange={handleTabChange} 
                  sx={{ mb: 2 }}
                  variant="fullWidth"
                >
                  <Tab label="Comments" value="1" />
                  <Tab label="Trades" value="2" />
                </Tabs>
  
                {tabValue === '1' && (
                  <Box>
                    {chatData.map((item, index) => (
                      <Win95Card key={index} sx={{ mb: 1 }}>
                        <CardContent>
                          <Box display='flex' alignItems='center' flexWrap="wrap">
                            <Link href={getHref(item.network, item.replyAddress)} target="_blank" rel="noopener noreferrer">
                              {item.replyAddress.slice(0, 5)}...{item.replyAddress.slice(-4)}
                            </Link>
                            <Win95Typography sx={{ ml: 1 }}>{dateFormat(item.date)}</Win95Typography>
                          </Box>
                          <Win95Typography sx={{ wordBreak: 'break-word' }}>{item.comment}</Win95Typography>
                        </CardContent>
                      </Win95Card>
                    ))}
                    <Win95Button onClick={handleOpenCommentDialog}>Post</Win95Button>
                  </Box>
                )}
  
                {tabValue === '2' && (
                  <Box sx={{ overflowX: 'auto' }}>
                    {tradeData.length > 0 ? (
                      <>
                        <Win95Typography variant="body2" gutterBottom>
                          Number of trades: {tradeData.length}
                        </Win95Typography>
                        <CustomPaginationActionsTable 
                          data={tradeData
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((trade) => ({
                              ...trade,
                              date: dateFormat(trade.date),
                              maticAmount: trade.ethAmount != null 
                                ? formatLargeNumber(toBigNumber(trade.ethAmount.toString()))
                                : 'N/A'
                            }))}
                        />
                      </>
                    ) : (
                      <Win95Typography>No trade data available.</Win95Typography>
                    )}
                  </Box>
                )}
              </CardContent>
            </Win95Card>
          </Grid2>
  
          <Grid2 xs={12} md={4}>
            <Win95Card>
              <CardContent>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Win95Button onClick={() => setType("BUY")} variant={type === "BUY" ? "contained" : "outlined"} fullWidth sx={{ mr: 1 }}>BUY</Win95Button>
                  <Win95Button onClick={() => setType("SELL")} variant={type === "SELL" ? "contained" : "outlined"} fullWidth>SELL</Win95Button>
                </Box>
  
                <Win95TextField
                  label={`Amount of tokens to ${type.toLowerCase()}`}
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    handleAmountChange(e.target.value).catch(error => {
                      console.error("Error in handleAmountChange:", error);
                      setTokenAmountError("An error occurred while calculating the price.");
                    });
                  }}
                  fullWidth
                  margin="normal"
                  helperText={type === "BUY" ? `Minimum purchase: ${ethers.formatEther(MIN_PURCHASE_AMOUNT)} POL` : ""}
                  error={!!tokenAmountError}
                />
  
                {showBuyRemaining && type === "BUY" && !tokenData.isLaunched && (
                  <Win95Button 
                    onClick={handleBuyRemaining}
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Buy Remaining Tokens
                  </Win95Button>
                )}
  
                {tokenAmountError && (
                  <Win95Typography color="error" sx={{ mt: 1 }}>
                    {tokenAmountError}
                  </Win95Typography>
                )}
  
                <Win95Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                  Estimated {type === "BUY" ? "cost" : "receive"}: {formatForDisplay(toBigNumber(calculatedCost))} POL
                </Win95Typography>
  
                <Box sx={{ mt: 3, mb: 2 }}>
                  <Win95Typography variant="body2" gutterBottom>
                    Tokens Available: {tokenData.isLaunched ? '0' : formatLargeNumber(new BigNumber(TOTAL_SUPPLY).times(0.7).minus(new BigNumber(TOTAL_SUPPLY).times(parseFloat(tokenData.percentageSold) / 100)))}
                  </Win95Typography>
                  <Win95Typography variant="body2" gutterBottom>
                  Tokens Sold: {(parseFloat(tokenData.percentageSold) / 0.7).toFixed(2)}%
                  </Win95Typography>
                  <Win95LinearProgress 
                    variant="determinate" 
                    value={tokenData.isLaunched ? 100 : Math.min((parseFloat(tokenData.percentageSold) / 0.7), 100)} 
                    sx={{ mt: 1 }}
                  />
                  {percentageSold === 0 && !tokenData.isLaunched && (
                    <Win95Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                      Be the first to buy this new token!
                    </Win95Typography>
                  )}
                </Box>
  
                <Win95Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                  Slippage Tolerance: {(slippage * 100).toFixed(1)}%
                </Win95Typography>
                <Box sx={{ px: 1, py: 2 }}>
                  <Win95Slider
                    value={slippageValues.indexOf(slippage)}
                    onChange={handleSlippageChange}
                    step={null}
                    marks={slippageMarks}
                    min={0}
                    max={slippageValues.length - 1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(index) => `${(slippageValues[index] * 100).toFixed(1)}%`}
                  />
                </Box>
  
                <Win95Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                  Your Balance: {formatLargeNumber(userTokenBalance)} {tokenData.tokenSymbol}
                </Win95Typography>
  
                <Win95Button 
                  onClick={handleBuySell} 
                  fullWidth 
                  sx={{ mt: 2 }}
                  disabled={!isConnected || tokenData.isLaunched || (type === "SELL" && userTokenBalance.isZero()) || !!tokenAmountError}
                >
                  {isConnected ? `Place ${type} Order` : 'Connect Wallet'}
                </Win95Button>
  
                {transactionError && (
                  <Win95Typography color="error" sx={{ mt: 2 }}>
                    {transactionError}
                  </Win95Typography>
                )}
  
                {transactionResult && (
                  <Win95Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
                    {transactionResult}
                  </Win95Typography>
                )}
              </CardContent>
            </Win95Card>
          </Grid2>
        </Grid2>
  
        {/* Confirmation Dialog */}
        <Dialog 
          open={showConfirmation} 
          onClose={() => !isTransactionInProgress && setShowConfirmation(false)} 
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              backgroundColor: windows95Theme.palette.background.paper,
              color: windows95Theme.palette.text.primary,
              border: `1px solid ${windows95Theme.palette.primary.main}`,
              borderRadius: 0,
            },
          }}
        >
          <DialogTitle>{`Confirm ${type} Transaction`}</DialogTitle>
          <DialogContent>
            <Win95Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
              You are about to {type.toLowerCase()} {formatLargeNumber(toBigNumber(confirmationDetails?.tokenAmount || "0"))} tokens
              {type === "BUY" 
                ? ` for ${formatLargeNumber(toBigNumber(confirmationDetails?.maticCost || "0"))} POL (including 3% fee)` 
                : `, which will result in receiving approximately ${formatLargeNumber(toBigNumber(confirmationDetails?.maticCost || "0"))} POL (after 3% fee)`}.
            </Win95Typography>
            {isTransactionInProgress && (
              <Box sx={{ mt: 2, width: '100%' }}>
                <Win95Typography variant="body2" sx={{ mb: 1 }}>Processing transaction...</Win95Typography>
                <AnimatedWin95ProgressBar />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Win95Button onClick={() => setShowConfirmation(false)} disabled={isTransactionInProgress}>Cancel</Win95Button>
            <Win95Button onClick={handleConfirmedTransaction} variant="contained" disabled={isTransactionInProgress}>
              {isTransactionInProgress ? 'Processing...' : 'Confirm'}
            </Win95Button>
          </DialogActions>
        </Dialog>
  
        {/* Comment Dialog */}
        <Dialog 
          open={openCommentDialog} 
          onClose={handleCloseCommentDialog} 
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              backgroundColor: windows95Theme.palette.background.paper,
              color: windows95Theme.palette.text.primary,
              border: `1px solid ${windows95Theme.palette.primary.main}`,
              borderRadius: 0,
            },
          }}
        >
          <DialogTitle>Add a comment</DialogTitle>
          <DialogContent>
            <Win95TextField 
              multiline 
              rows={4} 
              fullWidth 
              value={comment} 
              onChange={(e) => setComment(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Win95Button onClick={handleCloseCommentDialog}>Cancel</Win95Button>
            <Win95Button onClick={handlePostComment} variant="contained">Post</Win95Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default DetailPage;