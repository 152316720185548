const SEPOLIA_LP_CONTRACT_ADDRESS = "0x78d10FDA5670f8abe61682665e57213E63809A68";
const ETHEREUM_LP_CONTRACT_ADDRESS = "";
const BSC_LP_CONTRACT_ADDRESS = "";
const AVAX_LP_CONTRACT_ADDRESS = ""; 
const ARBITRUM_LP_CONTRACT_ADDRESS = ""; 
const BASE_LP_CONTRACT_ADDRESS = "";
const POLYGON_LP_CONTRACT_ADDRESS = "0x075572f8C138659826FAaf2D7b61e540dCD4C114";
const POLYGON_LIN_CONTRACT_ADDRESS = "0x8d6827bf7af483b71c26be6c232e89163036235e";
const POLYGON_LOG_CONTRACT_ADDRESS = "0xcaaf46a5ebeb3ecb1a3525d21a341ce87c783110"; 
const POLYGON_EXP_CONTRACT_ADDRESS = "0x074b5827c6b4021df7c0f6f6b6553fd2a1b16778";

const exponential = "0x074b5827c6b4021df7c0f6f6b6553fd2a1b16778";
const unpredictable = "0x3456789012345678901234567890123456789012";
const nosale = "0x4567890123456789012345678901234567890123";
const hitit = "0x5678901234567890123456789012345678901234";
const oneand = "0x6789012345678901234567890123456789012345";
const uponly = "0x7890123456789012345678901234567890123456";
const burnone = "0x8901234567890123456789012345678901234567";
const burntwo = "0x9012345678901234567890123456789012345678";
const burnfive = "0x0123456789012345678901234567890123456789";
const burner = "0x1234567890123456789012345678901234567890";
const eviltwin = "0x2345678901234567890123456789012345678901";
const timebomb = "0x3456789012345678901234567890123456789012";
const timebomb2 = "0x4567890123456789012345678901234567890123";
const velocity = "0x5678901234567890123456789012345678901234";
const gravity = "0x6789012345678901234567890123456789012345";
const decay = "0x7890123456789012345678901234567890123456";
const flip = "0x8901234567890123456789012345678901234567";
const feesus = "0x9012345678901234567890123456789012345678";
const babyfeesus = "0x0123456789012345678901234567890123456789";
const free = "0x1234567890123456789012345678901234567890";
const irs = "0x2345678901234567890123456789012345678901";
const gm = "0x2345998901234567890123456789012345678901";
const early = "0x2345678988234567890123456789012345678901";
const late = "0x2345678891234567890123456789012345678901";

export {SEPOLIA_LP_CONTRACT_ADDRESS,
    BSC_LP_CONTRACT_ADDRESS,
    ETHEREUM_LP_CONTRACT_ADDRESS,
    AVAX_LP_CONTRACT_ADDRESS,
    ARBITRUM_LP_CONTRACT_ADDRESS,
    BASE_LP_CONTRACT_ADDRESS,
    POLYGON_LP_CONTRACT_ADDRESS,
    POLYGON_LIN_CONTRACT_ADDRESS,
    POLYGON_LOG_CONTRACT_ADDRESS,
    POLYGON_EXP_CONTRACT_ADDRESS
};

export const POLYGON_RANDOM_CONTRACT_ADDRESSES = [
    exponential,
    unpredictable,
    nosale,
    hitit,
    oneand,
    uponly,
    burnone,
    burntwo,
    burnfive,
    burner,
    eviltwin,
    uponly,
    timebomb,
    timebomb2,
    velocity,
    gravity,
    decay,
    flip,
    feesus,
    babyfeesus,
    free,
    irs,
    gm,
    early,
    late
  ];