import React, { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { AppBar, Box, Button, Toolbar, Typography, styled, IconButton, useTheme } from "@mui/material";
import axios from "axios";
import { socket } from "../../utils/socket";
import { useNavigate } from "react-router-dom";

const Win95AppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
}));

const Win95Button = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: 'none',
  borderRadius: 0,
  padding: '2px 6px',
  fontFamily: '"VT323", monospace',
  fontSize: '1rem',
  fontWeight: 'normal',
  textTransform: 'none',
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  '&:active': {
    boxShadow: 'inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey',
  },
}));

const TokensBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  flex: 1,
  marginRight: '16px',
});

const TokenLink = styled('a')({
  marginRight: '0.5rem',
  padding: '0.25rem',
  textDecoration: 'none',
  color: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  flexShrink: 0,
});

const Win95Text = styled(Typography)({
  fontFamily: '"PT Sans", Arial, sans-serif',
  fontSize: '0.875rem',
  marginRight: '0.5rem',
});

const TokenImage = styled('img')({
  width: '16px',
  height: '16px',
  marginRight: '4px',
});

const IMAGE_BASE_URL = "https://lime-capitalist-mole-212.mypinata.cloud/ipfs/";

interface Transaction {
  id: number;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenImage: string;
  type: string;
  ethAmount: string;
}

interface HeaderProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

function Header({ drawerWidth, handleDrawerToggle }: HeaderProps) {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [latestBuyTokens, setLatestBuyTokens] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
  const theme = useTheme();

  const [typingIndex, setTypingIndex] = useState(0);
  const [currentTypedText, setCurrentTypedText] = useState('');
  const [visibleTokens, setVisibleTokens] = useState<Transaction[]>([]);

  const getLatestTrade = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`);
      if (!Array.isArray(response.data)) {
        setError("Invalid data format received from API");
        setLatestBuyTokens([]);
        return;
      }
      const buyTransactions = response.data.filter((trade: Transaction) => trade.type.toLowerCase() === 'buy');
      setLatestBuyTokens(buyTransactions);
    } catch (error) {
      console.error("Error fetching latest trades:", error);
      setError("Failed to fetch trade data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatestTrade();
    socket.on("message", getLatestTrade);
    return () => {
      socket.off("message", getLatestTrade);
    };
  }, []);

  useEffect(() => {
    if (latestBuyTokens.length > 0) {
      const currentToken = latestBuyTokens[typingIndex % latestBuyTokens.length];
      const fullText = `Buy ${currentToken.ethAmount} of ${currentToken.tokenSymbol}`;
      let charIndex = 0;

      const typingInterval = setInterval(() => {
        if (charIndex <= fullText.length) {
          setCurrentTypedText(fullText.slice(0, charIndex));
          charIndex++;
        } else {
          clearInterval(typingInterval);
          setTimeout(() => {
            setTypingIndex(prev => (prev + 1) % latestBuyTokens.length);
            setCurrentTypedText('');
            setVisibleTokens(prevTokens => {
              const newTokens = [...prevTokens, currentToken];
              if (newTokens.length > 5) {
                return [currentToken];
              }
              return newTokens;
            });
          }, 1000);
        }
      }, 50);

      return () => clearInterval(typingInterval);
    }
  }, [latestBuyTokens, typingIndex]);

  const renderTokens = () => {
    if (loading) return <Win95Text>Loading...</Win95Text>;
    if (error) return <Win95Text sx={{ color: 'error.main' }}>{error}</Win95Text>;
    if (visibleTokens.length === 0 && latestBuyTokens.length === 0) return <Win95Text>No buy transactions available</Win95Text>;

    return visibleTokens.map((item: Transaction, index: number) => (
      <TokenLink key={index} href={`/Polygon/${item.tokenAddress}`}>
        <Win95Text>
          {index === visibleTokens.length - 1 ? (
            <>
              {currentTypedText}
              <span style={{ borderLeft: '1px solid black', marginLeft: '2px' }}>&nbsp;</span>
            </>
          ) : (
            `Buy ${item.ethAmount} of ${item.tokenSymbol}`
          )}
        </Win95Text>
        <TokenImage 
          src={item.tokenImage ? `${IMAGE_BASE_URL}${item.tokenImage}` : `${IMAGE_BASE_URL}QmPQVWHogwehXJp6kr7EyL2k1fa2q4EsFbz7VKsDqmwViP`} 
          alt={item.tokenSymbol} 
        />
      </TokenLink>
    ));
  };

  return (
    <Win95AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ minHeight: '42px !important', px: 1 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ 
            mr: 1, 
            display: { sm: 'none' },
            '& .MuiSvgIcon-root': {
              fontSize: '1.2rem',
            }
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
          <TokensBox>
            {renderTokens()}
          </TokensBox>
          <Box sx={{ display: 'flex', gap: 1, flexShrink: 0 }}>
            <Win95Button onClick={() => open()} sx={{ mr: 1 }}>
              {isConnected ? `${address?.slice(0, 3)}...${address?.slice(-2)}` : "Connect"}
            </Win95Button>
            {(address?.toLowerCase() === ownerAddress?.toLowerCase()) && (
              <Win95Button 
                onClick={() => navigate('/admin')} 
              >
                Admin
              </Win95Button>
            )}
          </Box>
        </Box>
      </Toolbar>
    </Win95AppBar>
  );
}

export default Header;