import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { 
  Box, Container, FormControl, InputLabel, MenuItem, Pagination, 
  PaginationItem, Select, TextField, Typography, LinearProgress,
  SelectChangeEvent, Button, Dialog, DialogTitle, DialogContent,
  useMediaQuery, useTheme, ThemeProvider, CssBaseline
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import MainCard from "../components/cards/index";
import { ethers } from 'ethers';
import { getContractAddress, getContractABI } from "../utils/contractUtils";
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import windows95Theme from '../windows95Theme';


interface TokenInfo {
  id: number;
  network: string;
  tokenAddress: string;
  tokenImage: string;
  tokenName: string;
  tokenSymbol: string;
  marketcap: string;
  replies: number;
  creatorAddress: string;
  webLink: string;
  telegramLink: string;
  twitterLink: string;
  price: string;
  virtualLP: string;
  description: string;
  contracttype: number;
  createdAt: string;
}

interface TokenData {
  marketcap: number;
  virtualLP: number;
  price: number;
  percentageSold: number;
  trades: number;
  createdAt: string;
}

interface CachedTokenData extends TokenData {
  timestamp: number;
}

const getPricingMethodString = (contractType: number): string => {
  switch (contractType) {
    case 0:
      return ''; // Reserve Ratio (default)
    case 1:
      return 'linear';
    case 2:
      return 'logarithmic';
    case 3:
      return 'exponential';
    default:
      console.warn(`Unknown contract type: ${contractType}, defaulting to Reserve Ratio`);
      return '';
  }
};

const TOTAL_SUPPLY = ethers.parseUnits("1000000000", 18);

function MyTokens() {
  const [tokenList, setTokenList] = useState<(TokenInfo & { parsedData: TokenData })[]>([]);
  const [orderType, setOrderType] = useState('creationTime');
  const [orderFlag, setOrderFlag] = useState('DESC');
  const [searchWord, setSearchWord] = useState('');
  const [network, setNetwork] = useState('Polygon');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cachedData, setCachedData] = useState<{ [key: string]: CachedTokenData }>({});
  const { address, isConnected } = useWeb3ModalAccount();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedToken, setSelectedToken] = useState<TokenInfo | null>(null);

  const TOKENS_PER_PAGE = 4;
  const CACHE_DURATION = 5 * 60 * 1000;

  const getRpcUrl = useCallback((network: string) => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    if (!infuraProjectId) {
      console.error("Infura Project ID is not set");
      return null;
    }
    switch(network) {
      case "Polygon":
        return `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`;
      default:
        console.error("Unsupported network:", network);
        return null;
    }
  }, []);

  const initializeProvider = useMemo(() => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    if (!infuraProjectId) {
      console.error("Infura Project ID is not set");
      return null;
    }
    const rpcUrl = `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`;
    return new ethers.JsonRpcProvider(rpcUrl);
  }, []);

  const fetchTokenData = useCallback(async (token: TokenInfo): Promise<CachedTokenData> => {
    const cacheKey = `${token.network}-${token.tokenAddress}`;
    const now = Date.now();
  
    if (cachedData[cacheKey] && now - cachedData[cacheKey].timestamp < CACHE_DURATION) {
      return cachedData[cacheKey];
    }
  
    if (!initializeProvider) {
      throw new Error("Failed to initialize provider");
    }
  
    const pricingMethod = getPricingMethodString(token.contracttype);
    const contractABI = await getContractABI();
    const contractAddress = getContractAddress();
    const contract = new ethers.Contract(contractAddress, contractABI, initializeProvider);
  
    console.log(`Fetching data for token: ${token.tokenAddress}, Network: ${token.network}, Pricing Method: ${pricingMethod}, Contract Type: ${token.contracttype}`);
  
    try {
      const totalSupplyWei = BigInt(TOTAL_SUPPLY.toString());
      const totalSupplyEther = Number(ethers.formatUnits(totalSupplyWei, 18));
  
      let tokensSold: number;
      let price: number;
      let marketcap: number;
      let tokenReserve: number;
      let trades: number;
  
      if (token.contracttype === 0) { // Reserve Ratio
        const tokenReserveWei = await contract.getTokenReserve(token.tokenAddress);
        const adjustedTokenReserveWei = tokenReserveWei > totalSupplyWei ? totalSupplyWei : tokenReserveWei;
        const tokensSoldWei = totalSupplyWei - BigInt(adjustedTokenReserveWei.toString());
        
        tokenReserve = Number(ethers.formatUnits(adjustedTokenReserveWei, 18));
        tokensSold = Number(ethers.formatUnits(tokensSoldWei, 18));
        
        price = Number(ethers.formatUnits(await contract.getPrice(token.tokenAddress), 18));
        marketcap = price * totalSupplyEther;
        trades = Number(await contract.getTokenTrades(token.tokenAddress));
      } else {
        // For Linear, Logarithmic, and Exponential
        try {
          const [soldTokens, initialTokenReserve, currentTokenReserve, calculatedTokensSold] = await contract.getTokensSold(token.tokenAddress);
          tokensSold = Number(ethers.formatUnits(soldTokens, 18));
        } catch (error) {
          console.error("Error calling getTokensSold:", error);
          tokensSold = 0;
        }
  
        try {
          const tokenReserveWei = await contract.getTokenReserve(token.tokenAddress);
          const adjustedTokenReserveWei = tokenReserveWei > totalSupplyWei ? totalSupplyWei : tokenReserveWei;
          tokenReserve = Number(ethers.formatUnits(adjustedTokenReserveWei, 18));
        } catch (error) {
          console.error("Error calling getTokenReserve:", error);
          tokenReserve = 0;
        }
  
        try {
          trades = Number(await contract.getTokenTrades(token.tokenAddress));
        } catch (error) {
          console.error("Error calling getTokenTrades:", error);
          trades = 0;
        }
  
        try {
          price = Number(ethers.formatUnits(await contract.getPrice(token.tokenAddress), 18));
        } catch (error) {
          console.error("Error calling getPrice:", error);
          price = 0;
        }
  
        try {
          marketcap = price * totalSupplyEther;
        } catch (error) {
          console.error("Error calculating marketcap:", error);
          marketcap = 0;
        }
      }
  
      const percentageSold = (tokensSold / totalSupplyEther) * 100;
  
      const result: CachedTokenData = {
        marketcap: marketcap,
        virtualLP: tokenReserve,
        price: price,
        percentageSold: percentageSold,
        trades: trades,
        timestamp: now,
        createdAt: token.createdAt
      };
  
      console.log(`Token ${token.tokenAddress} (Type ${token.contracttype}) - Final result:`, result);
  
      setCachedData(prev => ({ ...prev, [cacheKey]: result }));
      return result;
    } catch (error) {
      console.error("Error fetching token data:", error, "for token:", token.tokenAddress);
      return {
        marketcap: 0,
        virtualLP: 0,
        price: 0,
        percentageSold: 0,
        trades: 0,
        timestamp: now,
        createdAt: token.createdAt
      };
    }
  }, [initializeProvider, cachedData]);

  const fetchData = useCallback(async () => {
    if (!isConnected || !address) {
      setError("Please connect your wallet to view your tokens.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_API_URL}/tokens/myPage`;
      console.log("Fetching data from URL:", url);
      console.log("With params:", { orderType, orderFlag, searchWord, network, pageNumber, pageSize: TOKENS_PER_PAGE, userAddress: address });
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderType,
          orderFlag,
          searchWord,
          network,
          pageNumber,
          pageSize: TOKENS_PER_PAGE,
          userAddress: address
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Response error:", response.status, errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const data = await response.json();
      console.log("Raw API response:", data);
  
      if (!data.tokenList || !Array.isArray(data.tokenList)) {
        throw new Error("Invalid response format: tokenList is missing or not an array");
      }
  
      const tokenListWithData = await Promise.all(data.tokenList.map(async (token: TokenInfo) => {
        console.log(`Processing token: ${token.tokenName}, createdAt: ${token.createdAt}`);
        const contractData = await fetchTokenData(token);
        return {
          ...token,
          parsedData: {
            ...contractData,
            createdAt: token.createdAt
          }
        };
      }));

      console.log("Processed token list:", tokenListWithData);

      setTokenList(tokenListWithData);
      setTotalPages(Math.ceil(data.totalCount / TOKENS_PER_PAGE));
    } catch (error) {
      console.error("Error in fetchData:", error);
      if (error instanceof Error) {
        setError(`Failed to fetch tokens: ${error.message}`);
      } else {
        setError("An unexpected error occurred while fetching tokens.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [orderType, orderFlag, searchWord, network, pageNumber, fetchTokenData, address, isConnected]);


  useEffect(() => {
    if (isConnected && address) {
      fetchData();
    }
  }, [fetchData, isConnected, address]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
    setPageNumber(1);
  };

  const handleOrderTypeChange = (event: SelectChangeEvent<string>) => {
    setOrderType(event.target.value);
    setPageNumber(1);
  };

  const handleOrderFlagChange = (event: SelectChangeEvent<string>) => {
    setOrderFlag(event.target.value);
    setPageNumber(1);
  };

  const handleHomeClick = () => {
    setPageNumber(1);
    setSearchWord('');
    setOrderType('creationTime');
    setOrderFlag('DESC');
  };

  const handleDescriptionClick = (token: TokenInfo) => (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Prevent the click from propagating to the card
    setSelectedToken(token);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedToken(null);
  };

  const theme = windows95Theme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={windows95Theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ bgcolor: 'background.default', py: 2 }}>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 2, 
            fontWeight: 'bold',
            fontFamily: '"VT323", monospace'  // Ensuring h4 uses VT323
          }}
        >
          My Tokens
        </Typography>
        
        <Box sx={{ mb: 2, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
          <FormControl fullWidth size="small" sx={{ flex: 1 }}>
            <InputLabel 
              id="order-type-label"
              sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}  // Consistent form input font
            >
              Sort By
            </InputLabel>
            <Select
              labelId="order-type-label"
              value={orderType}
              label="Sort By"
              onChange={handleOrderTypeChange}
              sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}  // Consistent form input font
            >
              <MenuItem value="marketcap" sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>Market Cap</MenuItem>
              <MenuItem value="creationTime" sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>Creation Time</MenuItem>
              <MenuItem value="updateTime" sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>Update Time</MenuItem>
            </Select>
          </FormControl>
          
          <TextField 
            fullWidth
            size="small"
            label="Search Keyword" 
            value={searchWord} 
            onChange={handleSearchChange}
            sx={{ 
              flex: 1,
              '& .MuiInputBase-input': {
                fontFamily: '"PT Sans", Arial, sans-serif'  // Consistent form input font
              },
              '& .MuiInputLabel-root': {
                fontFamily: '"PT Sans", Arial, sans-serif'  // Consistent form input font
              }
            }}
          />
          
          <FormControl fullWidth size="small" sx={{ flex: 1 }}>
            <InputLabel 
              id="order-flag-label"
              sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}  // Consistent form input font
            >
              Order
            </InputLabel>
            <Select
              labelId="order-flag-label"
              value={orderFlag}
              label="Order"
              onChange={handleOrderFlagChange}
              sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}  // Consistent form input font
            >
              <MenuItem value='ASC' sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>Ascending</MenuItem>
              <MenuItem value='DESC' sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>Descending</MenuItem>
            </Select>
          </FormControl>
          
          <Button
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={handleHomeClick}
            sx={{ 
              flex: 1, 
              height: '100%',
              fontFamily: '"VT323", monospace'  // Consistent button font
            }}
          >
            Reset
          </Button>
        </Box>
        
        {!isConnected || !address ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography 
              variant="h6" 
              sx={{ fontFamily: '"VT323", monospace' }}  // Consistent heading font
            >
              Please connect your wallet to view your tokens.
            </Typography>
          </Box>
        ) : isLoading ? (
          <Box sx={{ width: '100%', mb: 4 }}>
            <LinearProgress />
          </Box>
        ) : error ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography 
              color="error"
              sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}  // Consistent body text font
            >
              {error}
            </Typography>
          </Box>
        ) : tokenList.length > 0 ? (
          <Grid2 container spacing={2}>
            {tokenList.map((token, index) => (
              <Grid2 xs={12} sm={6} md={3} key={`${token.tokenAddress}-${index}`}>
                <Link to={`/${token.network}/${token.tokenAddress}`} style={{ textDecoration: 'none' }}>
                  <MainCard
                    network={token.network}
                    image={token.tokenImage}
                    title={token.tokenName}
                    ticker={token.tokenSymbol}
                    market_cap={token.parsedData.marketcap}
                    trades={token.parsedData.trades}
                    deployer={token.creatorAddress}
                    web={token.webLink}
                    telegram={token.telegramLink}
                    twitter={token.twitterLink}
                    percentageSold={token.parsedData.percentageSold}
                    tokenData={token.parsedData}
                    createdAt={token.createdAt}
                    description={token.description || "No description available"}
                    contracttype={token.contracttype}
                    onDescriptionClick={handleDescriptionClick(token)}
                    isMobile={isMobile}
                  />
                </Link>
              </Grid2>
            ))}
          </Grid2>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography 
              variant="h6"
              sx={{ fontFamily: '"VT323", monospace' }}  // Consistent heading font
            >
              No tokens found. Try adjusting your search or filters.
            </Typography>
          </Box>
        )}
        
        {tokenList.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? "small" : "medium"}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{ fontFamily: '"VT323", monospace' }}  // Consistent button/navigation font
                />
              )}
            />
          </Box>
        )}
        
        <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              '& .MuiDialog-paper': {
                bgcolor: 'background.paper'
              }
            }
          }}
        >
          <DialogTitle sx={{ fontFamily: '"VT323", monospace' }}>
            {selectedToken?.tokenName}
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontFamily: '"PT Sans", Arial, sans-serif' }}>
              {selectedToken?.description || "No description available"}
            </Typography>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}

export default MyTokens;