import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, CardMedia, Typography, Box, Chip, 
  LinearProgress, IconButton, Tooltip, styled, Dialog, DialogContent, DialogTitle
} from '@mui/material';
import { Language, Telegram, Twitter } from '@mui/icons-material';

// Google Fonts import
const googleFonts = `
  @import url('https://fonts.googleapis.com/css2?family=VT323&family=PT+Sans:wght@400;700&display=swap');
`;

// Add the font imports to the document
const style = document.createElement('style');
style.textContent = googleFonts;
document.head.appendChild(style);

interface TokenData {
  marketcap: number;
  virtualLP: number;
  price: number;
  percentageSold: number;
}

interface MainCardProps {
  network: string;
  image: string;
  title: string;
  ticker: string;
  market_cap: number;
  trades: number;
  deployer: string;
  web: string;
  telegram: string;
  twitter: string;
  percentageSold: number;
  tokenData: TokenData;
  createdAt?: string | Date;
  recentTransactions?: number;
  description?: string;
  contracttype: number;
  onDescriptionClick: (event: React.MouseEvent) => void;
  isMobile: boolean;
}

const Win95Card = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 0,
  boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf',
  transition: 'all 0.1s ease',
  '&:hover': {
    boxShadow: 'inset -2px -2px #0a0a0a, inset 2px 2px #fff, inset -3px -3px grey, inset 3px 3px #dfdfdf',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Win95Typography = styled(Typography)(({ theme }) => ({
  fontFamily: '"PT Sans", Arial, sans-serif',
  color: theme.palette.text.primary,
  '&.title': {
    fontFamily: '"VT323", monospace',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  '&.subtitle': {
    fontFamily: '"VT323", monospace',
    fontSize: '1rem',
  },
  '&.system-text': {
    fontFamily: '"VT323", monospace',
    fontSize: '0.9rem',
  },
}));

const Win95Chip = styled(Chip)<{ chiptype: 'STATUS' | 'CONTRACT' }>(({ theme, chiptype }) => ({
  fontFamily: '"VT323", monospace',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  backgroundColor: chiptype === 'STATUS' ? theme.palette.warning.main : theme.palette.info.main,
  color: theme.palette.text.primary,
  borderRadius: 0,
  marginRight: '5px',
  height: '24px',
  '& .MuiChip-label': {
    padding: '0 8px',
  },
}));

const Win95Progress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 0,
  backgroundColor: theme.palette.grey[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));

const Win95IconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Win95DialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: '"VT323", monospace',
  fontSize: '1.3rem',
  fontWeight: 'bold',
}));

const Win95DialogContent = styled(DialogContent)(({ theme }) => ({
  fontFamily: '"PT Sans", Arial, sans-serif',
  '& .debug-info': {
    fontFamily: '"VT323", monospace',
    fontSize: '0.8rem',
  },
}));

const MainCard: React.FC<MainCardProps> = ({ 
  network, image, title, ticker, market_cap, trades, deployer, web, telegram, twitter, 
  percentageSold, tokenData, createdAt, recentTransactions, description, contracttype, isMobile,
  onDescriptionClick
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [debugInfo, setDebugInfo] = useState<string>('');

  useEffect(() => {
    console.log('tokenData:', tokenData);
    console.log('percentageSold:', tokenData.percentageSold);
  }, [tokenData]);

  const formatNumber = (num: number) => {
    if (isNaN(num) || num === 0) return '0';
    if (num >= 1e9) return (num / 1e9).toFixed(2) + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(2) + 'K';
    return num.toFixed(2);
  };

  const formatPrice = (price: number) => {
    if (isNaN(price) || price === 0) return '0';
    
    const priceString = price.toFixed(18);
    const [integerPart, decimalPart] = priceString.split('.');
    
    if (integerPart !== '0') {
      return price.toFixed(4);
    }

    const leadingZeros = decimalPart.match(/^0+/)?.[0].length || 0;
    const significantDigits = decimalPart.slice(leadingZeros, leadingZeros + 4);

    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        0.0
        <sub style={{ fontSize: '0.7em', verticalAlign: 'bottom' }}>{leadingZeros}</sub>
        {significantDigits}
      </span>
    );
  };

  const formatPercentage = (percent: number) => {
    const adjustedPercent = (percent / 0.7) * 100;
    return Math.min(Math.max(adjustedPercent, 0), 100).toFixed(2);
  };

  const parseCreatedAt = (dateString: string | Date | undefined): Date | null => {
    if (!dateString) {
      console.log(`createdAt is ${dateString === null ? 'null' : 'undefined'} for token: ${title}`);
      return null;
    }
    if (dateString instanceof Date) {
      return dateString;
    }
    const parsedDate = new Date(dateString);
    if (isNaN(parsedDate.getTime())) {
      console.log(`Invalid createdAt date for token: ${title}. Value: ${dateString}`);
      return null;
    }
    return parsedDate;
  };

  const getChipStatus = (): string | null => {
    const parsedCreatedAt = parseCreatedAt(createdAt);
    if (!parsedCreatedAt) {
      return null;
    }
    
    const now = new Date();
    const hoursOld = (now.getTime() - parsedCreatedAt.getTime()) / (1000 * 60 * 60);
    
    if (hoursOld < 12) {
      return 'NEW';
    }
    if (recentTransactions && recentTransactions > 3) {
      return 'HOT';
    }
    return null;
  };

  const getContractTypeLabel = (type: number): string => {
    switch (type) {
      case 0: return 'EXPONENTIAL';
      case 1: return 'UNPREDICTABLE';
      case 2: return 'NO SALE';
      case 3: return 'HIT IT AND QUIT IT';
      case 4: return 'ONE AND DONE';
      case 5: return 'UP ONLY';
      case 6: return 'BURN ONE';
      case 7: return 'BURN TWO';
      case 8: return 'BURN FIVE';
      case 9: return 'BURNER';
      case 10: return 'EVIL TWIN';
      case 11: return 'TIMEBOMB';
      case 12: return 'TIMEBOMB TOO';
      case 13: return 'VELOCITY';
      case 14: return 'GRAVITY';
      case 15: return 'DECAY';
      case 16: return 'QUANTUM FLIP';
      case 17: return 'FEESUS';
      case 18: return 'BABY FEESUS';
      case 19: return 'FREE AND CLEAR';
      case 20: return 'THE IRS';
      case 21: return 'GM';
      case 22: return 'EARLY';
      case 23: return 'LATE';
      default: return 'UNKNOWN';
    }
  };

  const truncateDescription = (text: string | undefined, maxLength: number) => {
    if (text === undefined || text === null || text.trim() === '') return "No description";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const getContractTypeTooltip = (type: number): string => {
    switch (type) {
      case 0: return 'just a bonding curve';
      case 1: return 'unpredictable price changes on every transaction';
      case 2: return 'no sales until launch';
      case 3: return 'one buy, one sell allowed before launch';
      case 4: return 'one sell allowed before launch';
      case 5: return 'price up only';
      case 6: return 'burns 1% on buys';
      case 7: return 'burns 2% on buys';
      case 8: return 'burns 5% on buys';
      case 9: return 'burns random % on buys';
      case 10: return 'this token has a twin';
      case 11: return 'token is destroyed if not launched in 24 hours';
      case 12: return 'token is destroyed if not launched in 72 hours';
      case 13: return 'frequent buys increase price multiplier';
      case 14: return 'frequent buys hold price steady';
      case 15: return 'your tokens burn away daily';
      case 16: return 'every transaction has a chance of being affected by the quantum flip';
      case 17: return 'the fees are for us';
      case 18: return 'a little bit more for santa clause';
      case 19: return 'freedom to transact for free';
      case 20: return 'pay your taxes';
      case 21: return 'early bird gets the worm';
      case 22: return 'fashionably early';
      case 23: return 'fashionably late';
      default: return 'sum be broken';
    }
  };

  useEffect(() => {
    const chipStatus = getChipStatus();
    const contractTypeLabel = getContractTypeLabel(contracttype);
    const parsedCreatedAt = parseCreatedAt(createdAt);
    let createdAtInfo = parsedCreatedAt ? parsedCreatedAt.toISOString() : 'Invalid Date';
    setDebugInfo(`Status: ${chipStatus}, Contract: ${contractTypeLabel}, CreatedAt: ${createdAtInfo}, Original createdAt: ${createdAt}`);
  }, [createdAt, recentTransactions, contracttype, title]);

  const chipStatus = getChipStatus();
  const contractTypeLabel = getContractTypeLabel(contracttype);
  const contractTypeTooltip = getContractTypeTooltip(contracttype);

  return (
    <Win95Card>
      <CardMedia
        component="img"
        height={isMobile ? "120" : "140"}
        image={image ? `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${image}` : `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/QmPQVWHogwehXJp6kr7EyL2k1fa2q4EsFbz7VKsDqmwViP`}
        alt={title}
        sx={{ objectFit: 'contain', paddingTop: '10px' }}
      />
      <CardContent>
        <Win95Typography className="title" gutterBottom sx={{ wordBreak: 'break-word' }}>
          {title} ({ticker})
        </Win95Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
          {chipStatus && (
            <Win95Chip 
              label={chipStatus}
              size="small"
              chiptype="STATUS"
            />
          )}
          <Tooltip title={contractTypeTooltip} arrow>
            <Win95Chip 
              label={contractTypeLabel} 
              size="small"
              chiptype="CONTRACT"
            />
          </Tooltip>
        </Box>

        <Win95Typography 
          variant="body2" 
          sx={{ 
            mb: 1, 
            cursor: 'pointer', 
            '&:hover': { textDecoration: 'underline' },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          onClick={onDescriptionClick}
        >
          {truncateDescription(description, isMobile ? 30 : 50)}
        </Win95Typography>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Win95Typography className="subtitle">Market Cap:</Win95Typography>
          <Win95Typography className="system-text">${formatNumber(tokenData.marketcap)}</Win95Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Win95Typography className="subtitle">Price:</Win95Typography>
          <Win95Typography className="system-text">$
            {formatPrice(tokenData.price)}
          </Win95Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Win95Typography className="subtitle">Trades:</Win95Typography>
          <Win95Typography className="system-text">{trades || 0}</Win95Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
          <Win95Typography className="subtitle">Tokens Sold:</Win95Typography>
          <Win95Progress 
            variant="determinate" 
            value={Math.min(Math.max(tokenData.percentageSold, 0), 100)} 
            sx={{ mt: 1 }}
          />
          <Win95Typography className="system-text" align="right">
            {formatPercentage(tokenData.percentageSold)}%
          </Win95Typography>
        </Box>
        
        <Win95Typography className="subtitle" sx={{ mb: 1, wordBreak: 'break-all' }}>
          Deployer: {deployer ? `${deployer.slice(0, 6)}...${deployer.slice(-4)}` : 'N/A'}
        </Win95Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
          {web && (
            <Tooltip title="Website">
              <a href={web} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Win95IconButton>
                  <Language />
                </Win95IconButton>
              </a>
            </Tooltip>
          )}
          {telegram && (
            <Tooltip title="Telegram">
              <a href={telegram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Win95IconButton>
                  <Telegram />
                </Win95IconButton>
              </a>
            </Tooltip>
          )}
          {twitter && (
            <Tooltip title="Twitter">
              <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Win95IconButton>
                  <Twitter />
                </Win95IconButton>
              </a>
            </Tooltip>
          )}
        </Box>
      </CardContent>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <Win95DialogTitle>{title} Description</Win95DialogTitle>
        <Win95DialogContent>
          <Win95Typography>{description || "No description available"}</Win95Typography>
          <Win95Typography className="debug-info" color="textSecondary">
            Debug Info: {debugInfo}
          </Win95Typography>
        </Win95DialogContent>
      </Dialog>
    </Win95Card>
  );
};

export default MainCard;